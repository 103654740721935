.news-slider
	.swiper-slide
		height: auto
	
.news-item
	height: 100%
	+bg(white)
	.news-cate
		+fz-lh(16px, 24px)
		display: inline-flex
		align-items: center
		gap: r(6px)
		&:before
			content: ''
			+circle(r(6px))
			+bg(red)
	.news-title
		+font(700,18px,23px)
		+text(blue-2)
		+line(3)
	.link-view-details
		margin-top: auto
		span
			+trans-all
	+on-hover
		.news-title
			text-decoration: underline
		.link-view-details
			span
				transform: translateX(10px)
	&.news-row
		display: flex
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
		+mq-max(md)
			flex-direction: column
		.news-img
			+mq-min(md)
				+flex-width(46.3768116%)
			+context('.news-row', '.news-row.is-big')
				+mq-min(md)
					+flex-width(50%)
			figure
				+img-scale(213 / 320 * 100%)
				border-radius: 0px 0px r(80.5px) 0px
				overflow: hidden
				+context('.news-row', '.news-row.is-big')
					+mq-min(md)
						+img-scale(460 / 690 * 100%)
						border-radius: 0px 0px r(230px) 0px
		.news-caption
			padding: r(20px)
			+flex-gap-col(r(10px))
			flex: 1
			+context('.news-row', '.news-row.is-big')
				+mq-min(md)
					+flex-width(50%)
					padding: r(42px) r(57px) r(40px) r(63px)
		.news-brief
			+fz-lh(16px, 20px)
	&.news-col
		display: flex
		flex-direction: column
		+mq-max(md)
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
		.news-img
			figure
				+img-scale(213 / 320 * 100%)
				border-radius: 0px 0px r(80.5px) 0px
				overflow: hidden
				+mq-min(md)
					+img-scale(220 / 330 * 100%)
					border-radius: 0px 0px r(110px) 0px
		.news-caption
			padding: r(20px) r(30px)
			flex: 1 1 0%
			display: flex
			flex-direction: column
			gap: r(10px)
			+mq-max(lg)
				padding: r(20px)
		.news-title
			+mq-min(lg)
				height: r(140px)