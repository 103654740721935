.product-filter-list
	+flex-gap(r(20px))
	flex-wrap: wrap
	+mq-min(lg)
		gap: r(40px)
		justify-content: flex-end



.product-filter-item
	+flex-width(calc(33.333333% - 2.08333333rem))
	+flex-gap(r(14px))
	align-items: center
	+mq-max(lg)
		+flex-width(calc(50% - 1.04166666667rem))
		flex-direction: column
		align-items: stretch
	.product-filter-title
		+font(600,16px,24px)
	.product-filter-select
		flex: 1
		select
			+font(600,16px,24px)
			+text(grey-999)
			+bg(grey-bright)
			height: r(44px)
			border: 0
			+select-bg($bg: "../img/sl-grey.png", $pos: 95%)
			+mq-min(lg)
				padding: 0 r(30px) !important
				background-position: 90% 50%