footer
	.footer-bg
		height: 100%
		background: linear-gradient(180deg, #F0F5FB 0%, rgba(240, 245, 251, 0.5) 59.38%, #F0F5FB 100%), url(../img/footer.png)
		background-position-y: bottom
		background-repeat: no-repeat
		padding: r(40px) 0
		+mq-min(lg)
			padding: r(50px) 0

	.container
		+mq-min(lg)
			height: 100%

	.footer-top
		grid-area: top

	.footer-bottom
		margin-top: r(30px)
		+mq-min(lg)
			margin-top: r(60px)

	.footer-bottom-wrap
		+mq-max(lg)
			+flex-gap-col(r(15px))
			align-items: center
			text-align: center
		+mq-min(lg)
			+flex-gap(r(20px))
			align-items: center

	.footer-info
		+flex-gap-col(r(20px))
		h3
			@extend .text-heading-7
			+text(main)
			+mq-min(lg)
				height: r(72px)
		ul
			+flex-gap-col(r(20px))
			li
				+flex-gap(r(16px))
				+fz-lh(16px,20px)
				span
					+text(main)
					font-size: r(20px)

	.footer-profile
		margin-top: r(30px)
		&.hide-fullpage
			margin-top: 0
		&.on-fullpage
			display: none
		+mq-min(lg)
			margin-top: r(60px)
			&.hide-fullpage
				margin-top: 0
				margin-left: auto

	.footer-social
		ul
			+flex-gap(r(10px))
			li
				a
					+box(r(48px), r(48px))
					display: flex
					+flex-center
					+radius(r(5px))
					+bg(white)
					padding: 0 r(5px)
					.fa-facebook-f
						color: #3D6AD6
					.fa-youtube
						color: #FF0000

	.footer-copyright
		+fz-lh(16px, 24px)
		a
			+fz-lh(14px,18px)
			+text(grey-half)
			display: inline-block
			& + a
				margin-left: r(8px)

body:not(.fullpage)
	+mq-min(lg)
		main
			min-height: calc(100vh - 22.70833rem)
		footer
			min-height: r(436px)

.fullpage
	footer
		.footer-bg
			+mq-min(lg)
				display: flex
				align-items: center
				padding-top: r(160px)
				padding-bottom: r(50px)

		.footer-wrapper
			height: 100%
			+mq-min(lg)
				display: grid
				grid-template-columns: 1fr
				grid-template-rows: r(640px) r(48px)
				grid-column-gap: 0px
				grid-row-gap: 30px
				align-content: center
				grid-template-areas: "top" "bottom"

		.footer-top
			grid-area: top

		.footer-bottom
			grid-area: bottom
			+mq-min(lg)
				margin-top: 0
		.footer-profile.on-fullpage
			display: block
		.footer-profile.hide-fullpage
			display: none
