.home-partner
	.home-partner-bg
		+mq-min(lg)
			display: flex
			align-items: center
		+mq-max(lg)
			padding: r(40px) 0
			background-image: linear-gradient(180deg, rgba(6, 82, 158, 0) 0%, rgba(6, 82, 158, 0.668341) 41.15%, rgba(0, 90, 197, 0.9) 70.31%) !important

	.Module
		height: auto !important
		width: 100%
		.ModuleContent
			height: 100%

	.home-partner-wrapper
		width: 100%

	.home-partner-tabs
		margin-top: r(30px)

	.swiper-slide
		position: relative
		+mq-min(lg)
			&:before
				height: 1000px
				content: ''
				position: absolute
				top: 0
				left: 0
				border-left: 1px solid rgba(#DCBFB4, 0.3)

	.tabs-nav
		padding: r(12px) r(30px)
		background: rgba(17, 17, 17, 0.15)
		border-radius: 90px
		display: inline-flex
		margin-bottom: r(30px)
		&:before
			+bg(white)
		li
			a
				+text(white)

