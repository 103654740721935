.section-large
	padding: r(74px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section
	padding: r(60px) 0
	+mq-max(lg)
		padding: r(40px) 0

.section-small
	padding: r(40px) 0

.section-desc
	+fz-lh(18px, 24px)

.section-heading
	+mq-max(lg)
		flex-direction: column
		gap: r(20px)
		&:before
			display: none !important