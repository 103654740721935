.home-intro
	+mq-max(lg)
		padding-top: r(40px)
	.section-wrapper
		position: relative
		+mq-min(lg)
			overflow: hidden
			display: grid
			grid-template-rows: repeat(100, 1vh)
			grid-template-columns: repeat(100, 1vw)
	.home-intro-img
		.Module
			height: 100%
			.ModuleContent
				height: 100%
		+mq-max(lg)
			margin-top: r(30px)
			figure
				+img-scale(960 / 1920 * 100%)
		+mq-min(lg)
			grid-column-start: 1
			grid-column-end: 44
			grid-row-start: 1
			grid-row-end: 101
			// opacity: 0
			// transform: translateX(-10%)
			figure
				height: 100%
				border-radius: 0px 0px (480 / 860 * 100%) 0px
				overflow: hidden
				img
					+fit-cover
	.home-intro-text-wrap
		+mq-min(lg)
			grid-column-start: 50
			grid-row-start: 10
			grid-column-end: 87
			grid-row-end: 78
		.container
			+mq-min(lg)
				height: 100%
				max-width: none
				padding: 0

	.home-intro-text
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center
		gap: r(30px)
		+mq-min(lg)
			height: 100%
			// > *
			// 	opacity: 0
			// 	transform: translateY(20px)
		.section-desc
			+fz-lh(18px, 26px)
