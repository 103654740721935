.service-detail-heading
	padding: r(30px) 0 0
	+mq-min(lg)
		padding: r(74px) 0 0

.service-detail-content-item
	+even
		+bg(grey-bright)
		+mq-min(lg)
			.row
				flex-direction: row-reverse
			.content
				padding-left: r(60px)
	+odd
		+bg(white)
		+mq-min(lg)
			.content
				padding-right: r(60px)

	.content
		+mq-min(lg)
			display: flex
			flex-direction: column
		ul
			flex: 1 1 0px
			overflow-y: auto
			padding-right: r(15px)
			list-style: none
			padding-left: 0 
			margin-top: 0
			height: 100%
			li
				display: flex
				+after-first(1)
					margin-top: r(12px)
				&:before
					font-family: 'Font Awesome 6 Pro'
					content: '\f061'
					+text(main)
					padding-right: r(10px)
	.img 
		text-align: center
		img
			width: 100%
		+mq-max(lg)
			margin-top: r(30px)

.service-detail-form
	+bg-opa(main, 0.05)
	.img
		figure
			height: 100%
			img
				+fit-cover
	.service-form
		+mq-max(lg)
			margin-top: r(30px)
		+mq-min(lg)
			padding-left: r(60px)