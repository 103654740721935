.career-section-1
	.content
		+fz-lh(20px,30px)
		> * + *
			margin-top: r(20px)


.career-section-2
	background-image: url(../img/bg-career-1.jpg)
	.content
		+fz-lh(20px,30px)
		> * + *
			margin-top: r(20px)
	.img
		+mq-min(lg)
			padding-left: r(50px)

.career-list
	+fz-lh(18px, 26px)
	counter-reset: section
	.btn
		text-transform: none
		font-weight: 400
		+fz-lh(20px,26px)
		gap: r(20px)
	table
		width: 100%
		td,th
			padding: r(21px) r(15px)
		thead
			th
				text-transform: uppercase
				+bg(main)
				+text(white)
				font-weight: 700
		tbody
			tr
				&:hover
					+text(main)
				+even
					+bg(grey-bright)
			td
				text-align: center
			.num
				&::before
					counter-increment: section
					content: counter(section)
			.label
				display: inline-flex
				+box(r(50px),r(20px))
				+radius(r(4px))
				+bg(red)
				+text(white)
				font-size: r(14px)
				align-items: center
				justify-content: center
				margin-left: r(24px)
				+mq-max(md)
					margin-left: 0
			a
				+mq-max(md)
					display: block