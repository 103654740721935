.product-item
    &.is-equipment
        border: 0
        padding: 0
        box-shadow: r(6px) r(6px) r(10px) rgba(0, 0, 0, 0.1)
        border-radius: r(5px)
        background: #fff
        overflow: hidden
        .product-img
            background: #F2F7FF
            figure
                aspect-ratio: 330 / 235
        .product-caption
            padding: r(26px)
            flex: 1 1 0%
            display: flex
            flex-direction: column
            +mq-max(md)
                padding: r(15px)
        .product-title
            margin-top: 0
            margin-bottom: r(18px)
        .product-desc
            margin-bottom: r(30px)
            +mq-max(md)
                margin-bottom: r(15px)
        .product-subtitle
            +fz-lh(16px,22px)
            font-weight: 700
            margin-bottom: r(10px)
        .product-bottom
            margin-top: auto
            +mq-max(md)
                flex-direction: column
                gap: r(10px)
                align-items: flex-start !important
