.home-banner
	+bg(white)
	+mq-min(lg)
		padding-top: r(100px)
	.banner-item
		+mq-max(lg)
			+img-scale(960 / 1920 * 100%)
			iframe
				position: absolute
				top: 50%
				left: 50%
				width: 100%
				height: 100%
				transform: translateX(-50%) translateY(-50%)
		+mq-min(lg)
			figure
				height: calc(100vh - 5.20833rem)
				img,iframe
					+fit-cover
		iframe
			pointer-events: none
	.swiper-pagination
		bottom: r(60px)
		+mq-max(lg)
			bottom: r(30px)
	.swiper-pagination-bullet
		width: r(18px)
		height: r(18px)
		background: transparent
		border: r(2px) solid #fff
		opacity: 1
		&.swiper-pagination-bullet-active
			+bg(red)
			border-color: color(red)
