.tabs-nav
	+flex-gap(r(36px))
	align-items: center
	white-space: nowrap
	overflow-x: auto
	+mq-min(lg)
		&:before
			content: ''
			height: 1px
			+bg(main)
			+trans-all
			margin-right: r(-18px)
			width: r(50px)
	+mq-max(md)
		gap: r(40px)
		overflow-x: auto
		display: none
		padding: 1rem
		box-shadow: 0 4px 4px rgba(0,0,0,.1)
	li
		display: flex
		align-items: center
		+mq-max(lg)
			flex: 1
			white-space: nowrap
			+after-first(1)
				margin-top: r(16px)
		a
			+text(grey-999)
			@extend .sub-title
			+trans-all
			+parent-state('.active')
				font-weight: 700
				+text(main)
