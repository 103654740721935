.product-detail-page
	.sub-banner
		display: none
	
.product-detail-image-col
	+mq-min(lg)
		+flex-width((892 / 1440 * 100%))

.product-detail-content-col
	+mq-min(lg)
		+flex-width((488 / 1440 * 100%))

.product-detail-image-wrap
	display: flex
	justify-content: space-between
	align-items: center
	+mq-max(lg)
		flex-direction: column

.product-detail-thumbnail
	flex: 0 0 100%
	max-width: 100%
	.img
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		border: 1px solid transparent
		+img-scale(92 / 126 * 100%)
		img
			+fit-contain
	.swiper-slide-thumb-active
		.img
			border-color: color(main)
	.swiper-btn
		+center(X)
		top: auto
		width: r(40px)
		height: r(40px)
		margin: r(20px) 0
		font-size: r(20px)
		&.swiper-vertical-prev
			bottom: 100%
		&.swiper-vertical-next
			top: 100%
	+mq-max(lg)
		order: 1
		margin-top: 8px
		height: r(100px)
		width: 100%
		.swiper-container
			padding-bottom: 1px
	+mq-min(lg)
		+flex-width(r(126px))
		height: 100%
		.swiper-container
			height: r(425px)

.product-detail-images
	flex: 0 0 100%
	max-width: 100%
	.swiper-container
		border: 1px solid #E5E5E5
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))
	.img
		aspect-ratio: 746 / 550
		+img-scale(550 / 746 * 100%)
		img
			+fit-contain
	+mq-max(lg)
		order: 0
		width: 100%
	+mq-min(lg)
		+flex-width(calc( 100% - 6.5625rem ))
		padding-left: r(20px)

.product-detail-content
	+fz-lh(16px,24px)
	table
		width: 100%
		tr
			td
				padding: r(10px) r(20px)
				width: 50%
				+first(1)
					font-weight: 700
			+odd
				+bg(grey-bright)

.product-detail-content-bottom
	+flex-gap(r(36px))
	> *
		flex: 1 1 auto
	+mq-max(md)
		flex-direction: column
		gap: r(20px)

.product-detail-bottom
	margin-top: r(84px)
	+mq-max(lg)
		margin-top: r(30px)

.product-detail-attr-tabs
	.attr-tabs-nav
		+flex-gap(r(60px))
		+mq-max(lg)
			gap: r(30px)
			overflow-x: auto
		li
			+mq-max(lg)
				white-space: nowrap
			a
				+text(grey-half)
				position: relative
				display: inline-block
				padding-bottom: r(16px)
				+font(600, 18px,24px)
				+parent-state(".active",":hover")
					+text(main)
				&:before
					+pseudo
					width: 100%
					height: 1px
					+bg(main)
					bottom: 0
					left: 0
					opacity: 0
					+context('li','li.active')
						opacity: 1
	.tab-content
		box-shadow: r(6px) r(6px) r(10px) r(3px) rgba(0, 0, 0, 0.1)
		border-radius: r(5px)
		padding: r(30px)
		+fz-lh(16px,26px)
		h4
			font-weight: 600
			&:before
				+icon('\f058')
				font-weight: 700
				+text(blue-2)
				margin-right: (8px)
		h5
			font-weight: 600
		* + h4
			margin-top: r(30px)
		h5 + h4
			margin-top: 0
	.article-content
		+fz-lh(16px,26px)

.product-detail-other
	.row
		+gap-override(20px)
	.product-item
		+bg(white)
		&.is-row
			+mq-min(lg)
				border-radius: 5px
				border: 0
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
				.product-title
					margin-top: 0
				.product-img
					+flex-width(40%)
				.product-desc
					margin-bottom: r(20px)

.product-contact-form-popup
	width: r(990px)
	padding: 0
	+radius(r(10px))
	overflow: hidden
	.popup-content
		display: flex
		flex-wrap: wrap
		+mq-max(lg)
			display: block
	.img
		border: 1px solid #E5E5E5
		+mq-min(lg)
			+flex-width((451 / 990 * 100%))
		figure
			aspect-ratio: 451 / 557
			+mq-max(lg)
				aspect-ratio: 16 / 10
			img
				max-width: 80%
				margin: 0 auto
				display: block
				+fit-contain
	.product-contact-form
		padding: r(35px)
		+mq-min(lg)
			+flex-width((539 / 990 * 100%))
		.department
			pointer-events: none