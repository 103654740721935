.global-header
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: r(100px)
	background: #fff
	z-index: 99
	display: flex
	align-items: center
	+mq-max(lg)
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)

	.container
		width: 100%

	.header-logo
		+flex-width(184 / 1440 * 100%)
		+mq-max(lg)
			+flex-width(50%)

	.logo
		a
			+mq-min(lg)
				display: block
				aspect-ratio: 184 / 48
				img
					width: 100%
					height: 100%
					object-fit: contain
			+mq-max(lg)
				img
					height: r(50px)

	.header-util-wrapper
		+flex-width(r(62px))
		+mq-max(lg)
			+flex-width(50%)

	.menu-wrapper
		flex: 1

	.header-wrap
		display: flex
		align-items: center
		justify-content: center
		+mq-max(lg)
			justify-content: space-between

	.menu
		padding: 0 r(40px)
		ul
			+mq-min(lg)
				display: flex
				align-items: center
				justify-content: space-between
				gap: r(32px)
			li
				a
					font-size: r(18px)
					font-weight: 700
					white-space: nowrap
					+trans-all
					+parent-state(".active")
						+text(blue-2)
					+on-hover
						+text(blue-2)

	.header-util
		display: flex
		align-items: center
		font-size: r(16px)
		gap: r(14px)
		+text(main)
		+mq-max(lg)
			justify-content: flex-end
			font-size: r(24px)
			gap: r(20px)

	.searchbox
		position: absolute
		top: 100%
		right: 0
		margin-top: r(16px)
		display: flex
		align-items: center
		opacity: 0
		pointer-events: none
		+trans-all
		&.show
			opacity: 1
			pointer-events: auto
		input
			height: r(36px)
			width: r(200px)
			border: 1px solid color(grey-half)
			border-right: 0
			padding: 0 r(12px)
		button
			+box(r(36px))
			+bg(main)
			+text(white)
			font-size: r(16px)
			border: 0

	.menu-toggle
		+mq-max(lg)
			+box(r(50px), r(50px))
			display: flex
			+flex-center
			+bg(main)
			+text(white)
			font-size: r(24px)

	

.mobile-menu
	position: fixed
	width: 100vw
	height: 100vh
	z-index: 100
	display: none
	background: rgba(#000 , 0.7)
	.mobile-menu-overlay
		position: absolute
		z-index: 1
		height: 100%
		width: 100%
	.mobile-menu-wrapper
		display: flex
		justify-content: center
		align-items: center
		height: 100%
		width: 100%
		position: relative
		z-index: 2
		pointer-events: none
	.menu-wrapper
		border-radius: r(100px) 0
		width: 92vw
		background: linear-gradient(180deg, rgba(0,91,219,1) 50%, rgba(42,93,166,1) 100%)
		+text(white)
		height: 92vh
		transform: scale(1.15)
		opacity: 0
		display: flex
		pointer-events: all
		+flex-center
		margin: 0 auto
		overflow: auto
		> *
			height: 100%
	.menu
		text-align: center
		padding: 8vw 0
		ul
			li
				+after-first(1)
					margin-top: 8vw
				a
					font-size: 5vw