.shareholder-nav-item
	position: relative
	display: block
	&:before
		+pseudo
		+fill
		background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%)
		opacity: 0.5
		z-index: 1
		pointer-events: none
	.shareholder-nav-item-img
		img
			+fit-cover
		+parent-state('.is-big')
			figure
				aspect-ratio: 16 / 9
				+mq-min(lg)
					aspect-ratio: 448 / 602
		+parent-state('.is-small')
			figure
				aspect-ratio: 3 / 4
				+mq-min(lg)
					aspect-ratio: 448 / 286



	.shareholder-nav-item-title
		position: absolute
		left: 0
		bottom: 0
		width: 100%
		padding: r(31px) r(28px)
		z-index: 2
		+text(white)
		+mq-max(lg)
			padding: r(15px)