.has-divider
	position: relative
	padding-bottom: r(20px)
	+mq-min(lg)
		padding-bottom: r(30px)
	&:before
		+pseudo($width: r(50px), $height: 1px)
			bottom: 0
			+bg(main)
			+esc-parent('.text-white')
				+bg(white)
			+esc-parent('.text-center')
				+center(X)

.section-title, h1
	+font(600, 44px, 52px)

.sub-title
	+fz-lh(20px, 26px)

.text-normal
	+fz-lh(16px, 20px)

.text-heading-4
	+font(700, 32px, 44px)

.text-heading-5
	+font(700, 24px, 33px)

.text-heading-6
	+font(700, 20px, 24px)

.text-heading-7
	+font(700, 18px, 24px)

.text-heading-8
	+font(700, 16px, 22px)