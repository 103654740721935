.sub-banner
    figure
        +mq-min(md)
            +img-scale(750 / 1920 * 100%)
            iframe
                position: absolute
                top: 50%
                left: 50%
                width: 100%
                height: 100%
                transform: translateX(-50%) translateY(-50%)
