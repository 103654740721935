.career-detail-brief
	table
		width: 100%
		+fz-lh(16px,20px)
		+mq-max(lg)
			display: block
		tr
			+mq-max(lg)
				display: flex
				flex-wrap: wrap
			+even
				+mq-min(lg)
					+bg(grey-bright)
			td
				padding: r(15px) r(22px)
				+mq-max(lg)
					display: block
					+flex-width(50%)
					+between(3, 4)
						+bg(grey-bright)
				+mq-min(lg)
					&:first-child
						hite-space: nowrap
.career-detail-bottom
	margin-top: r(30px)
	padding-top: r(30px)
	border-top: 1px solid color(grey-bright)

.career-detail-content
	+fz-lh(16px,26px)
	table
		width: 100%
		+mq-max(lg)
			display: block
		tr
			+mq-max(lg)
				display: block
				+after-first(1)
					margin-top: r(24px)
			td
				+mq-min(lg)
					padding: r(25px) 0
				&:first-child
					+text(main)
					text-transform: uppercase
					padding-right: r(30px)
					font-weight: 700
				+mq-max(lg)
					display: block
					&:first-child
						margin-bottom: r(8px)

.career-detail-btn-group
	+flex-gap(r(23px))
	align-items: center
	margin-top: r(30px)
	span
		+fz-lh(18px,26px)

.career-detail-other
	.text-heading-5
		padding-bottom: r(12px)
	ul
		li
			+has-divider-bottom(r(10px), color(grey-light))
			a
				+fz-lh(16px,26px)
				font-weight: 600
				+parent-state(":hover")
					text-decoration: underline
			p
				+fz-lh(14px, 26px)


.apply-frm
	position: relative
	margin: 0
	background: #fff
	line-height: 1.375
	padding: r(52px) r(30px)
	+mq-min(sm)
		font-size: 16px
	.text-heading-4
		font-size: 2em
	.form-group
		.label
			display: none
		input[type="text"], input[type="date"], select, textarea
			padding: 0.75em 1em !important
			height: 3.125em
			font-size: 1em
		textarea
			height: 8em
	.frm-btnwrap
		margin-top: 0 
	.attachfile1
		.label
			display: flex
			align-items: center
			height: 3.125em
			padding: 0 2em
			+bg(grey-bright)
			+text(body)
			cursor: pointer
		.ruBrowse
			display: none
		.ruInputs
			li
				margin-bottom: 0
				height: auto
		.ruFileWrap
			height: auto

.recruitment-detail-page
	.fancybox-iframe
		width: 869px !important