.report-year-nav
	+flex-gap(r(20px))
	flex-wrap: wrap
	+mq-min(lg)
		+flex-gap(r(97px))
		justify-content: center
	li
		a
			+font(600, 24px, 24px)
			+text(grey-999)
			position: relative
			display: block
			padding-bottom: r(5px)
			+mq-min(lg)
				padding-bottom: r(16px)
			+mq-min(lg)
				+font(600, 48px, 26px)
			&:before
				+pseudo
				bottom: 0
				left: 0
				width: 100%
				height: 1px
				+bg(main)
				opacity: 0
			+parent-state(':hover','.active')
				+text(main)
				&:before
					opacity: 1

.report-list
	+mq-min(lg)
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
		padding: r(30px)
		margin: 0 r(-30px)

.report-item
	min-height: r(161px)
	+bg(grey-bright)
	padding: r(16px) r(20px)
	display: flex
	flex-direction: column
	.report-item-date 
		+flex-gap(r(15px))
		align-items: center
		+fz-lh(14px,18px)
		margin-bottom: r(9px)
		span
			+text(main)
			+fz-lh(20px, 26px)
	.report-item-title
		+font(600,18px,26px)
		margin-bottom: r(16px)
		+parent-state(":hover")
			text-decoration: underline
	.report-item-icon
		margin-top: auto
		font-size: r(24px)
		+text(main)
		text-align: right