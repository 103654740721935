.about-core
	.section-wrapper
		+mq-min(lg)
			display: flex
			padding-top: r(180px)
	.core-img
		display: flex
		align-items: flex-end
		+remove(lg,max)
		position: relative
		z-index: 2
		+mq-min(lg)
			height: 100%
		figure
			aspect-ratio: 505 / 778
			height: 100%
			img
				+fit-cover
	.row
		+mq-min(lg)
			height: 100%
			> *
				height: 100%

	.core-list-wrap
		+mq-min(lg)
			position: relative
			z-index: 1
			&:before
				+pseudo
					right: r(82px)
					left: 0
					bottom: 0
					+bg(main)
					border-radius: 0px 0px 0px 75%
					height: calc(100% - 5.3125rem)
					z-index: -1
					+mq-max(xxl)
						bottom: (5.3125 / 4) * 1rem

	.core-list
		+flex-gap(10px)
		flex-wrap: wrap
		+mq-max(lg)
			padding: r(40px) 0
		+mq-min(lg)
			justify-content: flex-end
			margin-left: r(-86px)
		> *
			width: 100%
			+mq-min(md)
				+calc("width", "50% - 5px")
		.core-item
			padding: r(30px)
			+bg(white)
			+flex-gap-col(r(20px))
			+radius(r(20px))
			box-shadow: 6px 6px 10px 2px rgba(0, 0, 0, 0.1)
			align-items: flex-start
			height: 100%
			.core-item-icon
				figure
					aspect-ratio: 43 / 57
					img
						+fit-contain
				
			.core-item-content
				+fz-lh(18px, 26px)