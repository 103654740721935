.about-history
	.about-history-bg
		padding: r(40px) 0
		+mq-min(lg)
			padding: r(69px) 0 0
			display: flex
			align-items: center

	.history-slider
		.swiper-slide
			height: auto
		.swiper-container
			padding: r(10px)
			margin: r(-10px)

	.swiper-slide
		+mq-min(lg)
			width: calc( 20% - 1.30208333333rem)
			margin-right: r(30px)
		+odd
			.history-item
				.history-year
					+bg-opa(blue-light, 0.9)
					+text(white)
				.history-content-wrap
					+bg(white)
		+even
			.history-item
				.history-year
					+bg(white)
					+text-opa(blue-light, 0.9)
				.history-content-wrap
					+bg-opa(blue-light, 0.9)
					+text(white)

	.history-item
		height: 100%
		+flex-gap-col(r(10px))
		.history-year
			height: r(200px)
			display: flex
			+flex-center
			+font(700, 56px, 76px)
			text-align: center
			box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
			border-radius: r(100px) 0px
		.history-content-wrap
			height: r(350px)
			padding: r(40px) r(26px) r(50px) r(30px)
			box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
			border-radius: 0px 0px r(120px) 0px
		.history-content
			+fz-lh(18px, 26px)
			max-height: r(260px)
			overflow-y: auto
			padding-right: (5px)
