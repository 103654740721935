.article-content
	line-height: 1.375
	+fz-lh(16px, 20px)
	> *
		+ *
			margin-top: r(16px)
	ul
		list-style: disc
		padding-left: 1.25rem
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top

.expand-content
	overflow: hidden
	&.show
		max-height: none !important

.scrollable-content
	+scrollbars(1px, color(main), color(white))
	overflow-y: auto