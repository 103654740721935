.breadcrumb-wrapper
	+remove(lg, 'max')
	+bg(grey-bright)
	+text(grey-999)
	.breadcrumb
		padding: r(6px) 0
		display: flex
		align-items: center
		li
			a
				+fz-lh(14px,18px)
				display: flex
				align-items: center
			+ li
				padding-left: r(16px)
				display: flex
				align-items: center
				&::before
					content: "|"
					margin-right: r(16px)
					font-weight: 400
					font-size: r(14px)
					position: relative
