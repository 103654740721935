.product-brand-wrap
	padding: r(49px) 0 r(60px)
	background-image: url(../img/product-cate-bg.png)
	background-position: center
	background-size: 100% auto
	background-repeat: no-repeat
	+mq-max(lg)
		padding: r(20px) 0 r(40px)

.product-brand-item
	display: block
	figure
		aspect-ratio: 450 / 252
		img
			+fit-cover