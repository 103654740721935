.about-member
	.about-member-bg
		position: relative
		z-index: 1
		+mq-max(lg)
			+bg(main)
			padding: r(40px) 0
		+mq-min(lg)
			&:before
				+pseudo
					+bg(main)
					width: 50%
					height: 100%
					left: 0
					top: 0
					z-index: -1
	.member-list-wrap
		+text(white)
		+mq-min(lg)
			padding-top: r(60px)
			padding-right: r(15px)
		
	.member-list-tabs
		ul
			+flex-gap(r(25px))
			margin-bottom: r(45px)
			li
				a
					+fz-lh(20px, 26px)
					+parent-state('.active')
						font-weight: 700

	.member-list
		+mq-min(lg)
			padding-right: r(73px)
			+scrollbars(r(2px), color(red), rgba(32, 134, 218, 0.9))
			max-height: r(435px)

	.member-item
		display: flex
		align-items: center
		justify-content: space-between
		padding: r(12px) r(30px)
		+trans-all
		+bg(white)
		+radius(r(10px))
		cursor: pointer
		+after-first(1)
			margin-top: r(10px)
		&.active
			+bg(blue-2)
		.member-name
			+text(blue-2)
			+parent-state('.active')
				+text(white)
		.member-content
			flex: 1
			padding-right: r(30px)
		.member-position
			margin-top: r(5px)
			+fz-lh(18px,26px)
			+text(body)
			+parent-state('.active')
				+text(white)
		.member-icon
			span
				display: flex
				+flex-center
				+circle(r(50px))
				+bg(blue-2)
				+text(white)
				font-size: r(32px)
			+parent-state('.active')
				span
					+bg(white)
					+text(blue-2)

	.member-detail-wrap
		+remove(lg,max)
		+mq-min(lg)
			padding-top: r(60px)
			padding-left: r(15px)

	.member-detail-item-pc
		display: none

	.member-detail-item
		+mq-max(lg)
			+bg(white)
			padding: r(30px)
			margin-top: r(10px)
			+radius(r(10px))
			+text(body)
		.member-detail-top
			+mq-min(sm)
				+flex-gap(r(30px))
		.member-detail-img
			text-align: center
			+mq-min(lg)
				+flex-width(r(328px))
				figure
					aspect-ratio: 328 / 414
					img
						+fit-cover
		.member-detail-position,.member-detail-qualification
			+fz-lh(18px,26px)
			margin-top: r(5px)
		hr
			border-top: 1px solid color(blue-2)
			background: transparent
			+mq-max(lg)
				border-top: 1px solid color(white)
		.member-detail-info
			+mq-max(sm)
				padding-top: r(24px)
			+mq-min(lg)
				padding-top: r(28px)
		.member-detail-bottom
			margin-top: r(20px)
			+mq-min(sm)
				margin-top: r(33px)
		.member-detail-content
			margin-top: r(17px)
			+mq-min(lg)
				+scrollbars(r(2px), color(red), rgba(32, 134, 218, 0.9))
				padding-right: r(26px)
				max-height: r(208px)