@charset "UTF-8";
/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

:root {
  --size: 1920
; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.5625rem;
  margin-bottom: -1.5625rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.5625rem 1.5625rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 1024px) {
  .row {
    margin-left: -1.25rem;
    margin-bottom: -1.25rem; }
  .row > * {
    padding: 0 0 1.25rem 1.25rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280.98px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280.98px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

body, html {
  line-height: 1;
  font-family: 'Manrope', sans-serif;
  color: #333;
  font-size: 3.6vw; }
  @media (min-width: 769px) {
    body, html {
      font-size: 1.5vw; } }
  @media (min-width: 1025px) {
    body, html {
      font-size: 1vw; } }

@media (min-width: 1025px) {
  .fullpage {
    overflow: hidden; } }

@media (max-width: 1024px) {
  .fullpage main {
    padding-top: 5.20833rem; } }

body:not(.fullpage) main {
  padding-top: 5.20833rem; }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

.fa-exclamation-triangle {
  line-height: 2.5;
  color: #ff3333;
  font-weight: 400;
  font-family: 'Manrope', sans-serif; }
  .fa-exclamation-triangle:before {
    font-family: 'Font Awesome 6 Pro'; }

.frm-btn-reset {
  display: none !important; }

.frm-btnwrap .label {
  display: none; }

@media (min-width: 1025px) {
  .section-fp-padding-top {
    padding-top: 5.20833rem;
    height: 100%; } }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 2.86458rem;
  right: 1.04167rem; }
  .fixed-stuff ul li:nth-child(n + 2) {
    margin-top: 0.52083rem; }
  .fixed-stuff ul li .btn-circle {
    transition: all 0.3s ease-in-out;
    width: 2.60417rem;
    height: 2.60417rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%);
    border: 1px solid #FFFFFF; }
  .fixed-stuff ul li.has-tooltip {
    position: relative; }
    .fixed-stuff ul li.has-tooltip .btn-circle {
      position: relative;
      z-index: 2; }
    .fixed-stuff ul li.has-tooltip .tooltip {
      position: absolute;
      right: 0;
      height: 2.08333rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%);
      border: 1px solid #FFFFFF;
      color: #fff;
      border-radius: 999px;
      z-index: 1;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      font-size: 0.9375rem;
      line-height: 1.33333; }
    .fixed-stuff ul li.has-tooltip:hover .tooltip {
      opacity: 1;
      pointer-events: all;
      padding: 0 4.16667rem 0 1.5625rem; }

footer .footer-bg {
  height: 100%;
  background: linear-gradient(180deg, #F0F5FB 0%, rgba(240, 245, 251, 0.5) 59.38%, #F0F5FB 100%), url(../img/footer.png);
  background-position-y: bottom;
  background-repeat: no-repeat;
  padding: 2.08333rem 0; }
  @media (min-width: 1025px) {
    footer .footer-bg {
      padding: 2.60417rem 0; } }

@media (min-width: 1025px) {
  footer .container {
    height: 100%; } }

footer .footer-top {
  grid-area: top; }

footer .footer-bottom {
  margin-top: 1.5625rem; }
  @media (min-width: 1025px) {
    footer .footer-bottom {
      margin-top: 3.125rem; } }

@media (max-width: 1024px) {
  footer .footer-bottom-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.78125rem;
    align-items: center;
    text-align: center; } }

@media (min-width: 1025px) {
  footer .footer-bottom-wrap {
    display: flex;
    gap: 1.04167rem;
    align-items: center; } }

footer .footer-info {
  display: flex;
  flex-direction: column;
  gap: 1.04167rem; }
  footer .footer-info h3 {
    color: #005BDB; }
    @media (min-width: 1025px) {
      footer .footer-info h3 {
        height: 3.75rem; } }
  footer .footer-info ul {
    display: flex;
    flex-direction: column;
    gap: 1.04167rem; }
    footer .footer-info ul li {
      display: flex;
      gap: 0.83333rem;
      font-size: 0.83333rem;
      line-height: 1.25; }
      footer .footer-info ul li span {
        color: #005BDB;
        font-size: 1.04167rem; }

footer .footer-profile {
  margin-top: 1.5625rem; }
  footer .footer-profile.hide-fullpage {
    margin-top: 0; }
  footer .footer-profile.on-fullpage {
    display: none; }
  @media (min-width: 1025px) {
    footer .footer-profile {
      margin-top: 3.125rem; }
      footer .footer-profile.hide-fullpage {
        margin-top: 0;
        margin-left: auto; } }

footer .footer-social ul {
  display: flex;
  gap: 0.52083rem; }
  footer .footer-social ul li a {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.26042rem;
    background-clip: padding-box;
    background-color: #fff;
    padding: 0 0.26042rem; }
    footer .footer-social ul li a .fa-facebook-f {
      color: #3D6AD6; }
    footer .footer-social ul li a .fa-youtube {
      color: #FF0000; }

footer .footer-copyright {
  font-size: 0.83333rem;
  line-height: 1.5; }
  footer .footer-copyright a {
    font-size: 0.72917rem;
    line-height: 1.28571;
    color: #CCCCCC;
    display: inline-block; }
    footer .footer-copyright a + a {
      margin-left: 0.41667rem; }

@media (min-width: 1025px) {
  body:not(.fullpage) main {
    min-height: calc(100vh - 22.70833rem); }
  body:not(.fullpage) footer {
    min-height: 22.70833rem; } }

@media (min-width: 1025px) {
  .fullpage footer .footer-bg {
    display: flex;
    align-items: center;
    padding-top: 8.33333rem;
    padding-bottom: 2.60417rem; } }

.fullpage footer .footer-wrapper {
  height: 100%; }
  @media (min-width: 1025px) {
    .fullpage footer .footer-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 33.33333rem 2.5rem;
      grid-column-gap: 0px;
      grid-row-gap: 30px;
      align-content: center;
      grid-template-areas: "top" "bottom"; } }

.fullpage footer .footer-top {
  grid-area: top; }

.fullpage footer .footer-bottom {
  grid-area: bottom; }
  @media (min-width: 1025px) {
    .fullpage footer .footer-bottom {
      margin-top: 0; } }

.fullpage footer .footer-profile.on-fullpage {
  display: block; }

.fullpage footer .footer-profile.hide-fullpage {
  display: none; }

.fp-nav {
  right: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  z-index: 21; }
  @media (max-width: 1024px) {
    .fp-nav {
      display: none; } }
  .fp-nav ul {
    display: flex;
    flex-direction: column;
    gap: 1.45833rem; }
    .fp-nav ul li {
      width: auto;
      height: auto;
      margin: 0;
      position: relative; }
      .fp-nav ul li a {
        display: block;
        position: relative;
        z-index: 1; }
        .fp-nav ul li a:before {
          content: "";
          display: block;
          position: absolute;
          border: 1px solid #005BDB;
          background-color: #fff;
          width: 1.04167rem;
          height: 1.04167rem;
          border-radius: 999999px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: all 0.3s ease-in-out; }
          .fp-nav ul li.active a:before {
            opacity: 1; }
        .fp-nav ul li a span {
          width: 0.625rem;
          height: 0.625rem;
          border-radius: 999999px;
          background-color: #005BDB;
          border: 0 !important;
          position: absolute;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block; }
      .fp-nav ul li .tooltip {
        position: absolute;
        background-color: #fff;
        color: #333;
        padding: 0.41667rem 1.66667rem 0.41667rem 0.625rem;
        font-size: 0.625rem;
        font-weight: 700;
        right: -0.72917rem;
        border-radius: 999px;
        background-clip: padding-box;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
        .fp-nav ul li.active .tooltip {
          opacity: 1; }

.global-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.20833rem;
  background: #fff;
  z-index: 99;
  display: flex;
  align-items: center; }
  @media (max-width: 1024px) {
    .global-header {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); } }
  .global-header .container {
    width: 100%; }
  .global-header .header-logo {
    max-width: 12.77778%;
    flex: 0 0 12.77778%;
    width: 100%; }
    @media (max-width: 1024px) {
      .global-header .header-logo {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
  @media (min-width: 1025px) {
    .global-header .logo a {
      display: block;
      aspect-ratio: 184 / 48; }
      .global-header .logo a img {
        width: 100%;
        height: 100%;
        object-fit: contain; } }
  @media (max-width: 1024px) {
    .global-header .logo a img {
      height: 2.60417rem; } }
  .global-header .header-util-wrapper {
    max-width: 3.22917rem;
    flex: 0 0 3.22917rem;
    width: 100%; }
    @media (max-width: 1024px) {
      .global-header .header-util-wrapper {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
  .global-header .menu-wrapper {
    flex: 1; }
  .global-header .header-wrap {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1024px) {
      .global-header .header-wrap {
        justify-content: space-between; } }
  .global-header .menu {
    padding: 0 2.08333rem; }
    @media (min-width: 1025px) {
      .global-header .menu ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.66667rem; } }
    .global-header .menu ul li a {
      font-size: 0.9375rem;
      font-weight: 700;
      white-space: nowrap;
      transition: all 0.3s ease-in-out; }
      .global-header .menu ul li.active a {
        color: #0044A5; }
      @media (hover: hover) and (pointer: fine) {
        .global-header .menu ul li a:hover {
          color: #0044A5; } }
  .global-header .header-util {
    display: flex;
    align-items: center;
    font-size: 0.83333rem;
    gap: 0.72917rem;
    color: #005BDB; }
    @media (max-width: 1024px) {
      .global-header .header-util {
        justify-content: flex-end;
        font-size: 1.25rem;
        gap: 1.04167rem; } }
  .global-header .searchbox {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.83333rem;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out; }
    .global-header .searchbox.show {
      opacity: 1;
      pointer-events: auto; }
    .global-header .searchbox input {
      height: 1.875rem;
      width: 10.41667rem;
      border: 1px solid #CCCCCC;
      border-right: 0;
      padding: 0 0.625rem; }
    .global-header .searchbox button {
      width: 1.875rem;
      height: 1.875rem;
      background-color: #005BDB;
      color: #fff;
      font-size: 0.83333rem;
      border: 0; }
  @media (max-width: 1024px) {
    .global-header .menu-toggle {
      width: 2.60417rem;
      height: 2.60417rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #005BDB;
      color: #fff;
      font-size: 1.25rem; } }

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
  background: rgba(0, 0, 0, 0.7); }
  .mobile-menu .mobile-menu-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .mobile-menu .mobile-menu-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    pointer-events: none; }
  .mobile-menu .menu-wrapper {
    border-radius: 5.20833rem 0;
    width: 92vw;
    background: linear-gradient(180deg, #005bdb 50%, #2a5da6 100%);
    color: #fff;
    height: 92vh;
    transform: scale(1.15);
    opacity: 0;
    display: flex;
    pointer-events: all;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: auto; }
    .mobile-menu .menu-wrapper > * {
      height: 100%; }
  .mobile-menu .menu {
    text-align: center;
    padding: 8vw 0; }
    .mobile-menu .menu ul li:nth-child(n + 2) {
      margin-top: 8vw; }
    .mobile-menu .menu ul li a {
      font-size: 5vw; }

.search-page {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .search-page {
      padding: 2.08333rem 0; } }
  .search-page h1 {
    margin-bottom: 1.04167rem;
    font-size: 2.08333rem;
    line-height: 1.2;
    font-weight: 700;
    color: #333333; }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.52083rem;
      z-index: 1;
      font-size: 1.25rem;
      color: #005BDB;
      width: 3.125rem;
      height: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.52083rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.125rem;
    height: 3.125rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.04167rem;
    padding-right: 2.8125rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.125rem !important;
    border-radius: 1.5625rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.08333rem;
    outline: none;
    width: 2.29167rem;
    height: 2.29167rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.04167rem;
    font-size: 0.72917rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 1.5625rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.5625rem; }
  .search-page .searchresult {
    margin-bottom: 1.5625rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 0.9375rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.52083rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.tabs-nav {
  display: flex;
  gap: 1.875rem;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto; }
  @media (min-width: 1025px) {
    .tabs-nav:before {
      content: '';
      height: 1px;
      background-color: #005BDB;
      transition: all 0.3s ease-in-out;
      margin-right: -0.9375rem;
      width: 2.60417rem; } }
  @media (max-width: 768px) {
    .tabs-nav {
      gap: 2.08333rem;
      overflow-x: auto;
      display: none;
      padding: 1rem;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); } }
  .tabs-nav li {
    display: flex;
    align-items: center; }
    @media (max-width: 1024px) {
      .tabs-nav li {
        flex: 1;
        white-space: nowrap; }
        .tabs-nav li:nth-child(n + 2) {
          margin-top: 0.83333rem; } }
    .tabs-nav li a {
      color: #999;
      transition: all 0.3s ease-in-out; }
      .tabs-nav li.active a {
        font-weight: 700;
        color: #005BDB; }

.breadcrumb-wrapper {
  background-color: #F5F5F5;
  color: #999; }
  @media (max-width: 1024px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.3125rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      font-size: 0.72917rem;
      line-height: 1.28571;
      display: flex;
      align-items: center; }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.83333rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "|";
        margin-right: 0.83333rem;
        font-weight: 400;
        font-size: 0.72917rem;
        position: relative; }

.news-slider .swiper-slide {
  height: auto; }

.news-item {
  height: 100%;
  background-color: #fff; }
  .news-item .news-cate {
    font-size: 0.83333rem;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    gap: 0.3125rem; }
    .news-item .news-cate:before {
      content: '';
      width: 0.3125rem;
      height: 0.3125rem;
      border-radius: 999999px;
      background-color: #E92429; }
  .news-item .news-title {
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.27778;
    color: #0044A5;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .news-item .link-view-details {
    margin-top: auto; }
    .news-item .link-view-details span {
      transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .news-item:hover .news-title {
      text-decoration: underline; }
    .news-item:hover .link-view-details span {
      transform: translateX(10px); } }
  .news-item.news-row {
    display: flex;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); }
    @media (max-width: 768px) {
      .news-item.news-row {
        flex-direction: column; } }
    @media (min-width: 769px) {
      .news-item.news-row .news-img {
        max-width: 46.37681%;
        flex: 0 0 46.37681%;
        width: 100%; } }
    @media (min-width: 769px) {
      .news-item.news-row.is-big .news-img {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
    .news-item.news-row .news-img figure {
      padding-top: 66.5625%;
      display: block;
      position: relative;
      border-radius: 0px 0px 4.19271rem 0px;
      overflow: hidden; }
      .news-item.news-row .news-img figure img, .news-item.news-row .news-img figure video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
      @media (min-width: 769px) {
        .news-item.news-row.is-big .news-img figure {
          padding-top: 66.66667%;
          display: block;
          position: relative;
          border-radius: 0px 0px 11.97917rem 0px; }
          .news-item.news-row.is-big .news-img figure img, .news-item.news-row.is-big .news-img figure video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover; } }
    .news-item.news-row .news-caption {
      padding: 1.04167rem;
      display: flex;
      flex-direction: column;
      gap: 0.52083rem;
      flex: 1; }
      @media (min-width: 769px) {
        .news-item.news-row.is-big .news-caption {
          max-width: 50%;
          flex: 0 0 50%;
          width: 100%;
          padding: 2.1875rem 2.96875rem 2.08333rem 3.28125rem; } }
    .news-item.news-row .news-brief {
      font-size: 0.83333rem;
      line-height: 1.25; }
  .news-item.news-col {
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .news-item.news-col {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); } }
    .news-item.news-col .news-img figure {
      padding-top: 66.5625%;
      display: block;
      position: relative;
      border-radius: 0px 0px 4.19271rem 0px;
      overflow: hidden; }
      .news-item.news-col .news-img figure img, .news-item.news-col .news-img figure video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
      @media (min-width: 769px) {
        .news-item.news-col .news-img figure {
          padding-top: 66.66667%;
          display: block;
          position: relative;
          border-radius: 0px 0px 5.72917rem 0px; }
          .news-item.news-col .news-img figure img, .news-item.news-col .news-img figure video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover; } }
    .news-item.news-col .news-caption {
      padding: 1.04167rem 1.5625rem;
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      gap: 0.52083rem; }
      @media (max-width: 1024px) {
        .news-item.news-col .news-caption {
          padding: 1.04167rem; } }
    @media (min-width: 1025px) {
      .news-item.news-col .news-title {
        height: 7.29167rem; } }

.product-compare {
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  background-color: #f5f5f5; }
  .product-compare .btn-minimize {
    font-size: 1.5625rem;
    color: #666;
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer; }
  .product-compare .compare-body {
    position: relative;
    padding: 3.64583rem 0; }
  .product-compare .product {
    position: relative; }
    .product-compare .product .remove-cpr {
      position: absolute;
      top: 1.04167rem;
      right: 1.04167rem;
      cursor: pointer;
      font-size: 20px; }
    .product-compare .product .img {
      padding-top: 100%;
      background: #fff;
      position: relative;
      display: block; }
      .product-compare .product .img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%; }
    .product-compare .product .caption {
      margin-top: 1rem;
      font-size: 1.04167rem;
      line-height: 1.35; }
  @media screen and (max-width: 1024px) {
    .product-compare .compare-body {
      padding: 2.08333rem 0; } }

.compare-products table {
  width: 100%;
  line-height: 1.5;
  font-size: 0.83333rem;
  line-height: 1.5;
  background-color: #E5E5E5;
  border: 1px solid #E5E5E5; }
  .compare-products table .product-img {
    width: 11.19792rem;
    margin: 0 auto; }
  .compare-products table figure {
    aspect-ratio: 215 / 120;
    display: block; }
    .compare-products table figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .compare-products table tr {
    background-color: #fff; }
    .compare-products table tr th {
      font-weight: 700;
      text-align: left;
      padding: 0.625rem 1.5625rem;
      width: 25%; }
    .compare-products table tr td {
      padding: 0.625rem 1.5625rem;
      text-align: center;
      vertical-align: top;
      width: 25%; }
      .compare-products table tr td:first-child {
        font-weight: 700; }
    .compare-products table tr:nth-child(odd) {
      background-color: #F5F5F5; }
  .compare-products table .product-header td {
    border-bottom: 0;
    padding-bottom: 0;
    position: relative; }
    .compare-products table .product-header td .remove-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px;
      font-size: 15px;
      cursor: pointer; }
  .compare-products table .product-name td {
    font-size: 2rem;
    text-align: center !important;
    border-top: 0; }

@media (min-width: 769px) {
  .sub-banner figure {
    padding-top: 39.0625%;
    display: block;
    position: relative; }
    .sub-banner figure img, .sub-banner figure video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .sub-banner figure iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%); } }

@media (min-width: 1025px) {
  .about-intro .section-wrapper {
    overflow: hidden;
    display: grid;
    grid-template-rows: repeat(100, 1vh);
    grid-template-columns: repeat(100, 1vw);
    position: relative; } }

@media (min-width: 1025px) {
  .about-intro .about-intro-img {
    grid-column-start: 1;
    grid-column-end: 60;
    grid-row-start: 1;
    grid-row-end: 101; } }

.about-intro .about-intro-img figure {
  height: 100%; }
  .about-intro .about-intro-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

@media (min-width: 1025px) {
  .about-intro .about-intro-content-wrap {
    position: absolute;
    top: 5.20833rem;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2; } }

@media (max-width: 1024px) {
  .about-intro .about-intro-content-wrap {
    background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%);
    padding: 1.5625rem 0; } }

.about-intro .about-intro-content {
  color: #fff; }
  @media (min-width: 1025px) {
    .about-intro .about-intro-content {
      padding: 3.125rem 0 3.125rem 5.98958rem; } }
  @media (max-width: 1600px) {
    .about-intro .about-intro-content {
      padding-left: 4.94792rem; } }
  @media (max-width: 1024px) {
    .about-intro .about-intro-content {
      padding: 0; } }
  .about-intro .about-intro-content .logo-icon {
    margin-bottom: 1.25rem;
    margin-left: -0.78125rem;
    width: 4.89583rem; }
    .about-intro .about-intro-content .logo-icon figure {
      aspect-ratio: 91 / 94; }
      .about-intro .about-intro-content .logo-icon figure img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    @media (min-width: 1025px) {
      .about-intro .about-intro-content .logo-icon {
        margin-bottom: 0.9375rem;
        margin-left: -3.125rem; }
        .about-intro .about-intro-content .logo-icon:after {
          content: '';
          display: block;
          width: 2.60417rem;
          height: 1px;
          background-color: #fff;
          margin-top: 1.66667rem;
          margin-left: 3.125rem; } }
  .about-intro .about-intro-content .article-content {
    font-size: 0.9375rem;
    line-height: 1.44444; }
    @media (min-width: 1025px) {
      .about-intro .about-intro-content .article-content {
        padding-right: 2.08333rem;
        max-height: 19.79167rem; } }

@media (max-width: 1024px) {
  .about-intro .about-intro-bg {
    display: none; } }

@media (min-width: 1025px) {
  .about-intro .about-intro-bg {
    grid-column-start: 60;
    grid-column-end: 101;
    grid-row-start: 1;
    grid-row-end: 101;
    position: relative;
    z-index: 1;
    background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%); } }

@media (min-width: 1025px) {
  .about-core .section-wrapper {
    display: flex;
    padding-top: 9.375rem; } }

.about-core .core-img {
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 2; }
  @media (max-width: 1024px) {
    .about-core .core-img {
      display: none; } }
  @media (min-width: 1025px) {
    .about-core .core-img {
      height: 100%; } }
  .about-core .core-img figure {
    aspect-ratio: 505 / 778;
    height: 100%; }
    .about-core .core-img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (min-width: 1025px) {
  .about-core .row {
    height: 100%; }
    .about-core .row > * {
      height: 100%; } }

@media (min-width: 1025px) {
  .about-core .core-list-wrap {
    position: relative;
    z-index: 1; }
    .about-core .core-list-wrap:before {
      content: "";
      display: block;
      position: absolute;
      right: 4.27083rem;
      left: 0;
      bottom: 0;
      background-color: #005BDB;
      border-radius: 0px 0px 0px 75%;
      height: calc(100% - 5.3125rem);
      z-index: -1; } }
    @media (min-width: 1025px) and (max-width: 1600px) {
      .about-core .core-list-wrap:before {
        bottom: 1.32813rem; } }

.about-core .core-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .about-core .core-list {
      padding: 2.08333rem 0; } }
  @media (min-width: 1025px) {
    .about-core .core-list {
      justify-content: flex-end;
      margin-left: -4.47917rem; } }
  .about-core .core-list > * {
    width: 100%; }
    @media (min-width: 769px) {
      .about-core .core-list > * {
        width: -webkit-calc(50% - 5px);
        width: calc(50% - 5px); } }
  .about-core .core-list .core-item {
    padding: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1.04167rem;
    border-radius: 1.04167rem;
    background-clip: padding-box;
    box-shadow: 6px 6px 10px 2px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    height: 100%; }
    .about-core .core-list .core-item .core-item-icon figure {
      aspect-ratio: 43 / 57; }
      .about-core .core-list .core-item .core-item-icon figure img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .about-core .core-list .core-item .core-item-content {
      font-size: 0.9375rem;
      line-height: 1.44444; }

.about-achievement .about-achievement-bg {
  background-color: rgba(0, 91, 219, 0.05);
  padding: 2.08333rem 0; }
  @media (min-width: 1025px) {
    .about-achievement .about-achievement-bg {
      padding: 3.125rem 0 0;
      display: flex;
      align-items: center; } }

.about-achievement .achievement-slider .swiper-btn {
  top: 30%; }

.about-achievement .achievement-slider .swiper-slide {
  width: calc(25% - 1.5625rem);
  margin-right: 1.5625rem;
  height: auto; }

.about-achievement .achievement-item {
  height: 100%;
  display: flex;
  flex-direction: column; }

.about-achievement .achievement-item-img {
  height: 14.53125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.875rem;
  background-color: #F5F5F5; }

.about-achievement .achievement-item-title {
  margin-top: 2.29167rem;
  margin-bottom: 0.52083rem;
  height: 2.5rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }
  @media (max-width: 1024px) {
    .about-achievement .achievement-item-title {
      margin-top: 1.25rem; } }

.about-achievement .achievement-item-brief {
  font-size: 0.83333rem;
  line-height: 1.5;
  margin-bottom: 2.23958rem; }
  @media (max-width: 1024px) {
    .about-achievement .achievement-item-brief {
      margin-bottom: 1.25rem; } }

.about-achievement .divider {
  border: 1px solid #E5E5E5;
  margin-bottom: 1.82292rem;
  margin-top: auto;
  position: relative; }
  @media (max-width: 1024px) {
    .about-achievement .divider {
      margin-bottom: 0.83333rem; } }
  .about-achievement .divider:before {
    content: "";
    display: block;
    position: absolute;
    width: 0.52083rem;
    height: 0.52083rem;
    border-radius: 999999px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #005BDB; }

.about-history .about-history-bg {
  padding: 2.08333rem 0; }
  @media (min-width: 1025px) {
    .about-history .about-history-bg {
      padding: 3.59375rem 0 0;
      display: flex;
      align-items: center; } }

.about-history .history-slider .swiper-slide {
  height: auto; }

.about-history .history-slider .swiper-container {
  padding: 0.52083rem;
  margin: -0.52083rem; }

@media (min-width: 1025px) {
  .about-history .swiper-slide {
    width: calc( 20% - 1.30208333333rem);
    margin-right: 1.5625rem; } }

.about-history .swiper-slide:nth-child(odd) .history-item .history-year {
  background-color: rgba(32, 134, 218, 0.9);
  color: #fff; }

.about-history .swiper-slide:nth-child(odd) .history-item .history-content-wrap {
  background-color: #fff; }

.about-history .swiper-slide:nth-child(even) .history-item .history-year {
  background-color: #fff;
  color: rgba(32, 134, 218, 0.9); }

.about-history .swiper-slide:nth-child(even) .history-item .history-content-wrap {
  background-color: rgba(32, 134, 218, 0.9);
  color: #fff; }

.about-history .history-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.52083rem; }
  .about-history .history-item .history-year {
    height: 10.41667rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 2.91667rem;
    line-height: 1.35714;
    text-align: center;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5.20833rem 0px; }
  .about-history .history-item .history-content-wrap {
    height: 18.22917rem;
    padding: 2.08333rem 1.35417rem 2.60417rem 1.5625rem;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 6.25rem 0px; }
  .about-history .history-item .history-content {
    font-size: 0.9375rem;
    line-height: 1.44444;
    max-height: 13.54167rem;
    overflow-y: auto;
    padding-right: 5px; }

.about-chart .section-wrapper {
  display: flex;
  align-items: center; }
  @media (max-width: 1024px) {
    .about-chart .section-wrapper {
      padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .about-chart .chart-img {
    height: 25rem; } }

.about-chart .chart-img figure {
  aspect-ratio: 1410 / 480; }
  .about-chart .chart-img figure img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.about-member .about-member-bg {
  position: relative;
  z-index: 1; }
  @media (max-width: 1024px) {
    .about-member .about-member-bg {
      background-color: #005BDB;
      padding: 2.08333rem 0; } }
  @media (min-width: 1025px) {
    .about-member .about-member-bg:before {
      content: "";
      display: block;
      position: absolute;
      background-color: #005BDB;
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1; } }

.about-member .member-list-wrap {
  color: #fff; }
  @media (min-width: 1025px) {
    .about-member .member-list-wrap {
      padding-top: 3.125rem;
      padding-right: 0.78125rem; } }

.about-member .member-list-tabs ul {
  display: flex;
  gap: 1.30208rem;
  margin-bottom: 2.34375rem; }
  .about-member .member-list-tabs ul li a {
    font-size: 1.04167rem;
    line-height: 1.3; }
    .about-member .member-list-tabs ul li.active a {
      font-weight: 700; }

@media (min-width: 1025px) {
  .about-member .member-list {
    padding-right: 3.80208rem;
    scrollbar-width: thin;
    max-height: 22.65625rem; }
    .about-member .member-list::-webkit-scrollbar {
      width: 0.10417rem;
      height: 0.10417rem; }
    .about-member .member-list::-webkit-scrollbar-thumb {
      background: #E92429; }
    .about-member .member-list::-webkit-scrollbar-track {
      background: rgba(32, 134, 218, 0.9); } }

.about-member .member-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1.5625rem;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 0.52083rem;
  background-clip: padding-box;
  cursor: pointer; }
  .about-member .member-item:nth-child(n + 2) {
    margin-top: 0.52083rem; }
  .about-member .member-item.active {
    background-color: #0044A5; }
  .about-member .member-item .member-name {
    color: #0044A5; }
    .about-member .member-item.active .member-name {
      color: #fff; }
  .about-member .member-item .member-content {
    flex: 1;
    padding-right: 1.5625rem; }
  .about-member .member-item .member-position {
    margin-top: 0.26042rem;
    font-size: 0.9375rem;
    line-height: 1.44444;
    color: #333; }
    .about-member .member-item.active .member-position {
      color: #fff; }
  .about-member .member-item .member-icon span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.60417rem;
    height: 2.60417rem;
    border-radius: 999999px;
    background-color: #0044A5;
    color: #fff;
    font-size: 1.66667rem; }
  .about-member .member-item.active .member-icon span {
    background-color: #fff;
    color: #0044A5; }

@media (max-width: 1024px) {
  .about-member .member-detail-wrap {
    display: none; } }

@media (min-width: 1025px) {
  .about-member .member-detail-wrap {
    padding-top: 3.125rem;
    padding-left: 0.78125rem; } }

.about-member .member-detail-item-pc {
  display: none; }

@media (max-width: 1024px) {
  .about-member .member-detail-item {
    background-color: #fff;
    padding: 1.5625rem;
    margin-top: 0.52083rem;
    border-radius: 0.52083rem;
    background-clip: padding-box;
    color: #333; } }

@media (min-width: 577px) {
  .about-member .member-detail-item .member-detail-top {
    display: flex;
    gap: 1.5625rem; } }

.about-member .member-detail-item .member-detail-img {
  text-align: center; }
  @media (min-width: 1025px) {
    .about-member .member-detail-item .member-detail-img {
      max-width: 17.08333rem;
      flex: 0 0 17.08333rem;
      width: 100%; }
      .about-member .member-detail-item .member-detail-img figure {
        aspect-ratio: 328 / 414; }
        .about-member .member-detail-item .member-detail-img figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

.about-member .member-detail-item .member-detail-position, .about-member .member-detail-item .member-detail-qualification {
  font-size: 0.9375rem;
  line-height: 1.44444;
  margin-top: 0.26042rem; }

.about-member .member-detail-item hr {
  border-top: 1px solid #0044A5;
  background: transparent; }
  @media (max-width: 1024px) {
    .about-member .member-detail-item hr {
      border-top: 1px solid #fff; } }

@media (max-width: 576px) {
  .about-member .member-detail-item .member-detail-info {
    padding-top: 1.25rem; } }

@media (min-width: 1025px) {
  .about-member .member-detail-item .member-detail-info {
    padding-top: 1.45833rem; } }

.about-member .member-detail-item .member-detail-bottom {
  margin-top: 1.04167rem; }
  @media (min-width: 577px) {
    .about-member .member-detail-item .member-detail-bottom {
      margin-top: 1.71875rem; } }

.about-member .member-detail-item .member-detail-content {
  margin-top: 0.88542rem; }
  @media (min-width: 1025px) {
    .about-member .member-detail-item .member-detail-content {
      scrollbar-width: thin;
      padding-right: 1.35417rem;
      max-height: 10.83333rem; }
      .about-member .member-detail-item .member-detail-content::-webkit-scrollbar {
        width: 0.10417rem;
        height: 0.10417rem; }
      .about-member .member-detail-item .member-detail-content::-webkit-scrollbar-thumb {
        background: #E92429; }
      .about-member .member-detail-item .member-detail-content::-webkit-scrollbar-track {
        background: rgba(32, 134, 218, 0.9); } }

.career-detail-brief table {
  width: 100%;
  font-size: 0.83333rem;
  line-height: 1.25; }
  @media (max-width: 1024px) {
    .career-detail-brief table {
      display: block; } }
  @media (max-width: 1024px) {
    .career-detail-brief table tr {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1025px) {
    .career-detail-brief table tr:nth-child(even) {
      background-color: #F5F5F5; } }
  .career-detail-brief table tr td {
    padding: 0.78125rem 1.14583rem; }
    @media (max-width: 1024px) {
      .career-detail-brief table tr td {
        display: block;
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; }
        .career-detail-brief table tr td:nth-child(n + 3):nth-child(-n + 4) {
          background-color: #F5F5F5; } }
    @media (min-width: 1025px) {
      .career-detail-brief table tr td:first-child {
        hite-space: nowrap; } }

.career-detail-bottom {
  margin-top: 1.5625rem;
  padding-top: 1.5625rem;
  border-top: 1px solid #F5F5F5; }

.career-detail-content {
  font-size: 0.83333rem;
  line-height: 1.625; }
  .career-detail-content table {
    width: 100%; }
    @media (max-width: 1024px) {
      .career-detail-content table {
        display: block; } }
    @media (max-width: 1024px) {
      .career-detail-content table tr {
        display: block; }
        .career-detail-content table tr:nth-child(n + 2) {
          margin-top: 1.25rem; } }
    @media (min-width: 1025px) {
      .career-detail-content table tr td {
        padding: 1.30208rem 0; } }
    .career-detail-content table tr td:first-child {
      color: #005BDB;
      text-transform: uppercase;
      padding-right: 1.5625rem;
      font-weight: 700; }
    @media (max-width: 1024px) {
      .career-detail-content table tr td {
        display: block; }
        .career-detail-content table tr td:first-child {
          margin-bottom: 0.41667rem; } }

.career-detail-btn-group {
  display: flex;
  gap: 1.19792rem;
  align-items: center;
  margin-top: 1.5625rem; }
  .career-detail-btn-group span {
    font-size: 0.9375rem;
    line-height: 1.44444; }

.career-detail-other .text-heading-5 {
  padding-bottom: 0.625rem; }

.career-detail-other ul li {
  margin-bottom: 0.52083rem;
  padding-bottom: 0.52083rem;
  border-bottom: 1px solid #E5E5E5; }
  .career-detail-other ul li a {
    font-size: 0.83333rem;
    line-height: 1.625;
    font-weight: 600; }
    .career-detail-other ul li:hover a {
      text-decoration: underline; }
  .career-detail-other ul li p {
    font-size: 0.72917rem;
    line-height: 1.85714; }

.apply-frm {
  position: relative;
  margin: 0;
  background: #fff;
  line-height: 1.375;
  padding: 2.70833rem 1.5625rem; }
  @media (min-width: 577px) {
    .apply-frm {
      font-size: 16px; } }
  .apply-frm .text-heading-4 {
    font-size: 2em; }
  .apply-frm .form-group .label {
    display: none; }
  .apply-frm .form-group input[type="text"], .apply-frm .form-group input[type="date"], .apply-frm .form-group select, .apply-frm .form-group textarea {
    padding: 0.75em 1em !important;
    height: 3.125em;
    font-size: 1em; }
  .apply-frm .form-group textarea {
    height: 8em; }
  .apply-frm .frm-btnwrap {
    margin-top: 0; }
  .apply-frm .attachfile1 .label {
    display: flex;
    align-items: center;
    height: 3.125em;
    padding: 0 2em;
    background-color: #F5F5F5;
    color: #333;
    cursor: pointer; }
  .apply-frm .attachfile1 .ruBrowse {
    display: none; }
  .apply-frm .attachfile1 .ruInputs li {
    margin-bottom: 0;
    height: auto; }
  .apply-frm .attachfile1 .ruFileWrap {
    height: auto; }

.recruitment-detail-page .fancybox-iframe {
  width: 869px !important; }

.career-section-1 .content {
  font-size: 1.04167rem;
  line-height: 1.5; }
  .career-section-1 .content > * + * {
    margin-top: 1.04167rem; }

.career-section-2 {
  background-image: url(../img/bg-career-1.jpg); }
  .career-section-2 .content {
    font-size: 1.04167rem;
    line-height: 1.5; }
    .career-section-2 .content > * + * {
      margin-top: 1.04167rem; }
  @media (min-width: 1025px) {
    .career-section-2 .img {
      padding-left: 2.60417rem; } }

.career-list {
  font-size: 0.9375rem;
  line-height: 1.44444;
  counter-reset: section; }
  .career-list .btn {
    text-transform: none;
    font-weight: 400;
    font-size: 1.04167rem;
    line-height: 1.3;
    gap: 1.04167rem; }
  .career-list table {
    width: 100%; }
    .career-list table td, .career-list table th {
      padding: 1.09375rem 0.78125rem; }
    .career-list table thead th {
      text-transform: uppercase;
      background-color: #005BDB;
      color: #fff;
      font-weight: 700; }
    .career-list table tbody tr:hover {
      color: #005BDB; }
    .career-list table tbody tr:nth-child(even) {
      background-color: #F5F5F5; }
    .career-list table tbody td {
      text-align: center; }
    .career-list table tbody .num::before {
      counter-increment: section;
      content: counter(section); }
    .career-list table tbody .label {
      display: inline-flex;
      width: 2.60417rem;
      height: 1.04167rem;
      border-radius: 0.20833rem;
      background-clip: padding-box;
      background-color: #E92429;
      color: #fff;
      font-size: 0.72917rem;
      align-items: center;
      justify-content: center;
      margin-left: 1.25rem; }
      @media (max-width: 768px) {
        .career-list table tbody .label {
          margin-left: 0; } }
    @media (max-width: 768px) {
      .career-list table tbody a {
        display: block; } }

.dealer-wrap {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05); }

.dealer-map {
  width: 100%;
  height: 100%; }
  .dealer-map #map_canvas, .dealer-map #mapIframe {
    height: 26.25rem;
    width: 100%; }
    @media (min-width: 1025px) {
      .dealer-map #map_canvas, .dealer-map #mapIframe {
        padding-left: 0.52083rem; } }

.dealer-result-list-wrap {
  height: 100%; }
  .dealer-result-list-wrap .dealer-result-list {
    max-height: 26.25rem;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 0.3125rem; }
    .dealer-result-list-wrap .dealer-result-list::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .dealer-result-list-wrap .dealer-result-list::-webkit-scrollbar-thumb {
      background: #06529E; }
    .dealer-result-list-wrap .dealer-result-list::-webkit-scrollbar-track {
      background: #F5F5F5; }
    .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap {
      cursor: pointer;
      padding: 0 1.5625rem;
      transition: 0.3s all; }
      .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap.active {
        background-color: #005BDB;
        color: #fff; }
      .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item {
        padding: 1.30208rem 0; }
        .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item p {
          font-size: 0.83333rem;
          line-height: 1.25; }
          .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item p:not(.name) {
            display: flex; }
          .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item p + p {
            margin-top: 0.36458rem; }
          .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item p:before {
            font-family: "Font Awesome 6 Pro";
            margin-right: 1.14583rem;
            font-weight: 300;
            width: 0.9375rem; }
        .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item .name {
          font-weight: 700;
          font-size: 1.04167rem;
          line-height: 1.2;
          color: #0044A5;
          margin-bottom: 0.98958rem;
          transition: 0.3s all; }
          .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap.active .dealer-item .name {
            color: #fff; }
        .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item .address:before {
          content: "\f3c5"; }
        .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item .phone:before {
          content: "\f095"; }
        .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap .dealer-item .fax:before {
          content: "\f67d"; }
      .dealer-result-list-wrap .dealer-result-list .dealer-item-wrap + .dealer-item-wrap .dealer-item {
        border-top: 1px solid #e5e5e5; }

.contact-form .img figure {
  height: 100%; }
  .contact-form .img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.contact-info table {
  width: 100%;
  font-size: 0.83333rem;
  line-height: 1.25; }
  .contact-info table th, .contact-info table td {
    padding: 0.52083rem 0.78125rem;
    text-align: left; }
  .contact-info table th, .contact-info table td:first-child {
    font-weight: 600; }
  .contact-info table th {
    white-space: nowrap; }
  .contact-info table td:first-child {
    padding-left: 1.5625rem; }
  .contact-info table tr:nth-child(odd) {
    background: #F5F5F5; }

.home-banner {
  background-color: #fff; }
  @media (min-width: 1025px) {
    .home-banner {
      padding-top: 5.20833rem; } }
  @media (max-width: 1024px) {
    .home-banner .banner-item {
      padding-top: 50%;
      display: block;
      position: relative; }
      .home-banner .banner-item img, .home-banner .banner-item video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
      .home-banner .banner-item iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translateX(-50%) translateY(-50%); } }
  @media (min-width: 1025px) {
    .home-banner .banner-item figure {
      height: calc(100vh - 5.20833rem); }
      .home-banner .banner-item figure img, .home-banner .banner-item figure iframe {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .home-banner .banner-item iframe {
    pointer-events: none; }
  .home-banner .swiper-pagination {
    bottom: 3.125rem; }
    @media (max-width: 1024px) {
      .home-banner .swiper-pagination {
        bottom: 1.5625rem; } }
  .home-banner .swiper-pagination-bullet {
    width: 0.9375rem;
    height: 0.9375rem;
    background: transparent;
    border: 0.10417rem solid #fff;
    opacity: 1; }
    .home-banner .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #E92429;
      border-color: #E92429; }

@media (max-width: 1024px) {
  .home-intro {
    padding-top: 2.08333rem; } }

.home-intro .section-wrapper {
  position: relative; }
  @media (min-width: 1025px) {
    .home-intro .section-wrapper {
      overflow: hidden;
      display: grid;
      grid-template-rows: repeat(100, 1vh);
      grid-template-columns: repeat(100, 1vw); } }

.home-intro .home-intro-img .Module {
  height: 100%; }
  .home-intro .home-intro-img .Module .ModuleContent {
    height: 100%; }

@media (max-width: 1024px) {
  .home-intro .home-intro-img {
    margin-top: 1.5625rem; }
    .home-intro .home-intro-img figure {
      padding-top: 50%;
      display: block;
      position: relative; }
      .home-intro .home-intro-img figure img, .home-intro .home-intro-img figure video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; } }

@media (min-width: 1025px) {
  .home-intro .home-intro-img {
    grid-column-start: 1;
    grid-column-end: 44;
    grid-row-start: 1;
    grid-row-end: 101; }
    .home-intro .home-intro-img figure {
      height: 100%;
      border-radius: 0px 0px 55.81395% 0px;
      overflow: hidden; }
      .home-intro .home-intro-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

@media (min-width: 1025px) {
  .home-intro .home-intro-text-wrap {
    grid-column-start: 50;
    grid-row-start: 10;
    grid-column-end: 87;
    grid-row-end: 78; } }

@media (min-width: 1025px) {
  .home-intro .home-intro-text-wrap .container {
    height: 100%;
    max-width: none;
    padding: 0; } }

.home-intro .home-intro-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5625rem; }
  @media (min-width: 1025px) {
    .home-intro .home-intro-text {
      height: 100%; } }
  .home-intro .home-intro-text .section-desc {
    font-size: 0.9375rem;
    line-height: 1.44444; }

@media (max-width: 1024px) {
  .home-statistic .home-statistic-bg {
    background: none !important;
    padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .home-statistic .home-statistic-bg {
    display: flex;
    align-items: center; } }

@media (min-width: 1025px) {
  .home-statistic .home-statistic-wrapper {
    width: 100%; } }

.home-statistic .stat-item {
  margin: 0 auto;
  border: 1px solid rgba(214, 214, 214, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.85417rem;
  height: 8.85417rem;
  border-radius: 999999px; }
  @media (min-width: 1025px) {
    .home-statistic .stat-item {
      border: 1px solid rgba(255, 255, 255, 0.5);
      width: 10.41667rem;
      height: 10.41667rem;
      border-radius: 999999px;
      padding: 0.52083rem;
      transition: all 0.3s ease-in-out; } }
  .home-statistic .stat-item .stat-item-inner {
    background: linear-gradient(180deg, rgba(0, 91, 219, 0.8) 0%, rgba(65, 117, 133, 0.16) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1.30208rem;
    color: #fff;
    width: 7.8125rem;
    height: 7.8125rem;
    border-radius: 999999px; }
    @media (min-width: 1025px) {
      .home-statistic .stat-item .stat-item-inner {
        width: 9.375rem;
        height: 9.375rem;
        border-radius: 999999px;
        transition: all 0.3s ease-in-out; } }
    .home-statistic .stat-item .stat-item-inner .stat-num, .home-statistic .stat-item .stat-item-inner .stat-desc {
      transition: all 0.3s ease-in-out; }
    .home-statistic .stat-item .stat-item-inner .stat-num {
      font-weight: 800;
      font-size: 2.91667rem;
      line-height: 1.35714; }
      @media (max-width: 1024px) {
        .home-statistic .stat-item .stat-item-inner .stat-num {
          font-size: 2.5rem;
          line-height: 1; } }
  @media (hover: hover) and (pointer: fine) {
    .home-statistic .stat-item:hover {
      transform: scale(1.2); }
      .home-statistic .stat-item:hover .stat-item-inner {
        background: linear-gradient(180deg, rgba(255, 0, 35, 0.8) 0%, rgba(255, 92, 0, 0.16) 100%); }
        .home-statistic .stat-item:hover .stat-item-inner .stat-num, .home-statistic .stat-item:hover .stat-item-inner .stat-desc {
          transform: scale(0.9); } }

.home-products .section-wrapper {
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    .home-products .section-wrapper {
      height: 100%; }
      .home-products .section-wrapper .row {
        height: 100%; } }

@media (min-width: 1025px) {
  .home-products .section-title {
    position: absolute;
    top: 8.33333rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; } }

@media (max-width: 1024px) {
  .home-products .section-title {
    color: #005BDB !important; }
    .home-products .section-title:before {
      background: #005BDB !important; } }

.home-products .product-nav-col {
  position: relative;
  z-index: 1; }
  @media (hover: hover) and (pointer: fine) {
    .home-products .product-nav-col:hover {
      z-index: 4; }
      .home-products .product-nav-col:hover .product-nav-item {
        background-image: none !important; } }

.home-products .product-nav-list {
  height: 100%; }

.home-products .product-nav-hover-list {
  position: absolute;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.home-products .product-nav-hover-item {
  position: absolute;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  will-change: opacity, transform; }
  .home-products .product-nav-hover-item:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .home-products .product-nav-hover-item .product-nav-hover-content {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.66667rem;
    line-height: 1.375;
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 0 4.6875rem;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s ease-in-out; }
  .home-products .product-nav-hover-item.show {
    opacity: 1;
    z-index: 3; }
    .home-products .product-nav-hover-item.show .product-nav-hover-content {
      opacity: 1;
      transform: none; }

.home-products .product-nav-item {
  position: relative;
  display: block;
  color: #fff; }
  @media (max-width: 1024px) {
    .home-products .product-nav-item {
      padding: 2.08333rem 1.04167rem;
      height: 100%; } }
  @media (min-width: 1025px) {
    .home-products .product-nav-item {
      height: 100%; } }
  .home-products .product-nav-item:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    z-index: 1; }
  .home-products .product-nav-item .product-nav-inner {
    position: relative;
    z-index: 2; }
    @media (min-width: 1025px) {
      .home-products .product-nav-item .product-nav-inner {
        height: 11.97917rem; } }
  .home-products .product-nav-item .product-nav-button {
    position: absolute;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10.41667rem;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out; }
    .home-products .product-nav-item:hover .product-nav-button {
      opacity: 1; }
    @media (max-width: 1024px) {
      .home-products .product-nav-item .product-nav-button {
        display: none; } }
  .home-products .product-nav-item .product-nav-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .home-products .product-nav-item .product-nav-icon {
    width: 7.29167rem;
    height: 7.29167rem;
    border-radius: 999999px;
    margin: 0 auto;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.33333rem; }
  .home-products .product-nav-item .product-nav-title {
    text-align: center;
    margin-top: 0.52083rem; }

@media (max-width: 1024px) {
  .home-service {
    padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .home-service .section-wrapper {
    padding-bottom: 1.5625rem;
    display: flex;
    align-items: center;
    height: 100%; } }

@media (min-width: 1025px) {
  .home-service .home-service-wrapper {
    width: 100%; } }

@media (min-width: 1025px) {
  .home-service .home-service-list .container {
    max-width: none;
    padding: 0; } }

.home-service .home-service-item {
  position: relative;
  display: block; }
  .home-service .home-service-item .home-service-item-img figure {
    aspect-ratio: 960 / 300; }
    @media (max-width: 1024px) {
      .home-service .home-service-item .home-service-item-img figure {
        aspect-ratio: 16 / 10; } }
    .home-service .home-service-item .home-service-item-img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (min-width: 1025px) {
    .home-service .home-service-item .home-service-item-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.72917rem 1.5625rem;
      background: linear-gradient(180deg, rgba(0, 91, 219, 0.8) 0%, rgba(65, 117, 133, 0.16) 100%);
      text-align: center;
      color: #fff; } }
  @media (max-width: 1024px) {
    .home-service .home-service-item .home-service-item-caption {
      margin-top: 0.625rem; } }

@media (min-width: 1025px) {
  .home-partner .home-partner-bg {
    display: flex;
    align-items: center; } }

@media (max-width: 1024px) {
  .home-partner .home-partner-bg {
    padding: 2.08333rem 0;
    background-image: linear-gradient(180deg, rgba(6, 82, 158, 0) 0%, rgba(6, 82, 158, 0.668341) 41.15%, rgba(0, 90, 197, 0.9) 70.31%) !important; } }

.home-partner .Module {
  height: auto !important;
  width: 100%; }
  .home-partner .Module .ModuleContent {
    height: 100%; }

.home-partner .home-partner-wrapper {
  width: 100%; }

.home-partner .home-partner-tabs {
  margin-top: 1.5625rem; }

.home-partner .swiper-slide {
  position: relative; }
  @media (min-width: 1025px) {
    .home-partner .swiper-slide:before {
      height: 1000px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-left: 1px solid rgba(220, 191, 180, 0.3); } }

.home-partner .tabs-nav {
  padding: 0.625rem 1.5625rem;
  background: rgba(17, 17, 17, 0.15);
  border-radius: 90px;
  display: inline-flex;
  margin-bottom: 1.5625rem; }
  .home-partner .tabs-nav:before {
    background-color: #fff; }
  .home-partner .tabs-nav li a {
    color: #fff; }

.home-news .Module {
  height: auto !important;
  width: 100%; }
  .home-news .Module .ModuleContent {
    height: 100%; }

@media (max-width: 1024px) {
  .home-news .home-news-bg {
    padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .home-news .home-news-bg {
    display: flex;
    align-items: center; } }

.home-news .home-news-wrapper {
  width: 100%; }

.home-shareholder .Module {
  height: auto !important;
  width: 100%; }
  .home-shareholder .Module .ModuleContent {
    height: 100%; }

@media (min-width: 1025px) {
  .home-shareholder .home-shareholder-bg {
    display: flex;
    align-items: center; } }

@media (max-width: 1024px) {
  .home-shareholder .home-shareholder-bg {
    background: #fdfdfd !important;
    padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .home-shareholder .home-shareholder-wrapper {
    width: 100%; } }

@media (max-width: 1024px) {
  .home-shareholder .section-title {
    color: #0044A5; } }

.home-shareholder .home-shareholder-tabs {
  display: flex;
  align-items: center; }
  @media (min-width: 1025px) {
    .home-shareholder .home-shareholder-tabs {
      justify-content: flex-end; } }

.shareholder-news-item {
  display: block; }
  .shareholder-news-item .shareholder-news-title {
    justify-content: space-between;
    align-items: center;
    display: flex; }
  .shareholder-news-item .shareholder-news-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (min-width: 1025px) {
    .shareholder-news-item.shareholder-news-item-big {
      aspect-ratio: 690 / 530; } }
  .shareholder-news-item.shareholder-news-item-big .shareholder-news-img figure {
    aspect-ratio: 690 / 430; }
  .shareholder-news-item.shareholder-news-item-big .shareholder-news-title {
    background-color: #fff;
    color: #0044A5;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    padding: 1.04167rem 1.5625rem; }
    @media (min-width: 1025px) {
      .shareholder-news-item.shareholder-news-item-big .shareholder-news-title {
        aspect-ratio: 690 / 100; } }
    @media (max-width: 1024px) {
      .shareholder-news-item.shareholder-news-item-big .shareholder-news-title {
        padding: 0.78125rem; } }
    .shareholder-news-item.shareholder-news-item-big .shareholder-news-title h3 {
      padding-right: 2.60417rem; }
      @media (min-width: 1025px) {
        .shareholder-news-item.shareholder-news-item-big .shareholder-news-title h3 {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical; } }
  .shareholder-news-item.shareholder-news-item-small {
    aspect-ratio: 330 / 250;
    position: relative; }
    .shareholder-news-item.shareholder-news-item-small:before {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }
    .shareholder-news-item.shareholder-news-item-small .shareholder-news-img {
      height: 100%; }
      .shareholder-news-item.shareholder-news-item-small .shareholder-news-img figure {
        height: 100%; }
    .shareholder-news-item.shareholder-news-item-small .shareholder-news-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.83333rem 1.5625rem;
      z-index: 2;
      color: #fff;
      font-weight: 600;
      font-size: 1.04167rem;
      line-height: 1.25; }
      @media (max-width: 1024px) {
        .shareholder-news-item.shareholder-news-item-small .shareholder-news-title {
          padding: 0.78125rem; } }
      .shareholder-news-item.shareholder-news-item-small .shareholder-news-title h3 {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-right: 1rem; }

.detail-date {
  font-size: 0.72917rem;
  line-height: 1.28571;
  color: #999; }

.news-detail-meta {
  padding-bottom: 1.04167rem;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 1.04167rem; }

@media (min-width: 1025px) {
  .news-other-list .news-item.news-row .news-img {
    max-width: 45.07111%;
    flex: 0 0 45.07111%;
    width: 100%; }
    .news-other-list .news-item.news-row .news-img figure {
      border-radius: 0px 0px 3.51563rem 0px; }
  .news-other-list .news-item.news-row .news-caption {
    padding: 0.3125rem 0.83333rem 0.625rem 1.82292rem;
    gap: 0.3125rem; }
  .news-other-list .news-item.news-row .news-title {
    font-size: 0.72917rem;
    line-height: 1.28571; }
  .news-other-list .news-item.news-row .link-view-details {
    font-size: 0.72917rem; } }

.partner-detail-img figure {
  aspect-ratio: 569 / 321; }
  .partner-detail-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.partner-detail-other .partner-item .partner-name {
  color: #0044A5; }

@media (min-width: 1025px) {
  .partner-item {
    max-width: 10.41667rem;
    margin: 0 auto; } }

.partner-item .partner-logo {
  background: linear-gradient(-135deg, #06529E 100%, #2AA8E0 100%);
  padding: 0.20833rem;
  border-radius: 0.52083rem;
  background-clip: padding-box;
  overflow: hidden; }
  @media (min-width: 1025px) {
    .partner-item .partner-logo {
      height: 5.20833rem; } }
  @media (max-width: 1024px) {
    .partner-item .partner-logo {
      aspect-ratio: 196 / 100; } }
  .partner-item .partner-logo figure {
    padding-top: 48.02041%;
    display: block;
    position: relative;
    border-radius: 0.3125rem;
    background-clip: padding-box;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    position: relative; }
    .partner-item .partner-logo figure img, .partner-item .partner-logo figure video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .partner-item .partner-logo figure img {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%; }

.partner-item .partner-name {
  text-align: center;
  margin-top: 0.78125rem;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 1.44444; }

.product-brand-wrap {
  padding: 2.55208rem 0 3.125rem;
  background-image: url(../img/product-cate-bg.png);
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat; }
  @media (max-width: 1024px) {
    .product-brand-wrap {
      padding: 1.04167rem 0 2.08333rem; } }

.product-brand-item {
  display: block; }
  .product-brand-item figure {
    aspect-ratio: 450 / 252; }
    .product-brand-item figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.product-detail-page .sub-banner {
  display: none; }

@media (min-width: 1025px) {
  .product-detail-image-col {
    max-width: 61.94444%;
    flex: 0 0 61.94444%;
    width: 100%; } }

@media (min-width: 1025px) {
  .product-detail-content-col {
    max-width: 33.88889%;
    flex: 0 0 33.88889%;
    width: 100%; } }

.product-detail-image-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 1024px) {
    .product-detail-image-wrap {
      flex-direction: column; } }

.product-detail-thumbnail {
  flex: 0 0 100%;
  max-width: 100%; }
  .product-detail-thumbnail .img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    padding-top: 73.01587%;
    display: block;
    position: relative; }
    .product-detail-thumbnail .img img, .product-detail-thumbnail .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .product-detail-thumbnail .img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .product-detail-thumbnail .swiper-slide-thumb-active .img {
    border-color: #005BDB; }
  .product-detail-thumbnail .swiper-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    width: 2.08333rem;
    height: 2.08333rem;
    margin: 1.04167rem 0;
    font-size: 1.04167rem; }
    .product-detail-thumbnail .swiper-btn.swiper-vertical-prev {
      bottom: 100%; }
    .product-detail-thumbnail .swiper-btn.swiper-vertical-next {
      top: 100%; }
  @media (max-width: 1024px) {
    .product-detail-thumbnail {
      order: 1;
      margin-top: 8px;
      height: 5.20833rem;
      width: 100%; }
      .product-detail-thumbnail .swiper-container {
        padding-bottom: 1px; } }
  @media (min-width: 1025px) {
    .product-detail-thumbnail {
      max-width: 6.5625rem;
      flex: 0 0 6.5625rem;
      width: 100%;
      height: 100%; }
      .product-detail-thumbnail .swiper-container {
        height: 22.13542rem; } }

.product-detail-images {
  flex: 0 0 100%;
  max-width: 100%; }
  .product-detail-images .swiper-container {
    border: 1px solid #E5E5E5;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05)); }
  .product-detail-images .img {
    aspect-ratio: 746 / 550;
    padding-top: 73.72654%;
    display: block;
    position: relative; }
    .product-detail-images .img img, .product-detail-images .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .product-detail-images .img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  @media (max-width: 1024px) {
    .product-detail-images {
      order: 0;
      width: 100%; } }
  @media (min-width: 1025px) {
    .product-detail-images {
      max-width: calc( 100% - 6.5625rem);
      flex: 0 0 calc( 100% - 6.5625rem);
      width: 100%;
      padding-left: 1.04167rem; } }

.product-detail-content {
  font-size: 0.83333rem;
  line-height: 1.5; }
  .product-detail-content table {
    width: 100%; }
    .product-detail-content table tr td {
      padding: 0.52083rem 1.04167rem;
      width: 50%; }
      .product-detail-content table tr td:first-child {
        font-weight: 700; }
    .product-detail-content table tr:nth-child(odd) {
      background-color: #F5F5F5; }

.product-detail-content-bottom {
  display: flex;
  gap: 1.875rem; }
  .product-detail-content-bottom > * {
    flex: 1 1 auto; }
  @media (max-width: 768px) {
    .product-detail-content-bottom {
      flex-direction: column;
      gap: 1.04167rem; } }

.product-detail-bottom {
  margin-top: 4.375rem; }
  @media (max-width: 1024px) {
    .product-detail-bottom {
      margin-top: 1.5625rem; } }

.product-detail-attr-tabs .attr-tabs-nav {
  display: flex;
  gap: 3.125rem; }
  @media (max-width: 1024px) {
    .product-detail-attr-tabs .attr-tabs-nav {
      gap: 1.5625rem;
      overflow-x: auto; } }
  @media (max-width: 1024px) {
    .product-detail-attr-tabs .attr-tabs-nav li {
      white-space: nowrap; } }
  .product-detail-attr-tabs .attr-tabs-nav li a {
    color: #CCCCCC;
    position: relative;
    display: inline-block;
    padding-bottom: 0.83333rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.33333; }
    .product-detail-attr-tabs .attr-tabs-nav li.active a {
      color: #005BDB; }
    .product-detail-attr-tabs .attr-tabs-nav li:hover a {
      color: #005BDB; }
    .product-detail-attr-tabs .attr-tabs-nav li a:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #005BDB;
      bottom: 0;
      left: 0;
      opacity: 0; }
      .product-detail-attr-tabs .attr-tabs-nav li.active a:before {
        opacity: 1; }

.product-detail-attr-tabs .tab-content {
  box-shadow: 0.3125rem 0.3125rem 0.52083rem 0.15625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.26042rem;
  padding: 1.5625rem;
  font-size: 0.83333rem;
  line-height: 1.625; }
  .product-detail-attr-tabs .tab-content h4 {
    font-weight: 600; }
    .product-detail-attr-tabs .tab-content h4:before {
      content: "";
      font-family: 'Font Awesome 6 Pro';
      font-weight: 700;
      color: #0044A5;
      margin-right: 8px; }
  .product-detail-attr-tabs .tab-content h5 {
    font-weight: 600; }
  .product-detail-attr-tabs .tab-content * + h4 {
    margin-top: 1.5625rem; }
  .product-detail-attr-tabs .tab-content h5 + h4 {
    margin-top: 0; }

.product-detail-attr-tabs .article-content {
  font-size: 0.83333rem;
  line-height: 1.625; }

.product-detail-other .row {
  margin-left: -1.04167rem;
  margin-bottom: -1.04167rem; }
  .product-detail-other .row > * {
    padding-left: 1.04167rem;
    padding-bottom: 1.04167rem; }

.product-detail-other .product-item {
  background-color: #fff; }
  @media (min-width: 1025px) {
    .product-detail-other .product-item.is-row {
      border-radius: 5px;
      border: 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); }
      .product-detail-other .product-item.is-row .product-title {
        margin-top: 0; }
      .product-detail-other .product-item.is-row .product-img {
        max-width: 40%;
        flex: 0 0 40%;
        width: 100%; }
      .product-detail-other .product-item.is-row .product-desc {
        margin-bottom: 1.04167rem; } }

.product-contact-form-popup {
  width: 51.5625rem;
  padding: 0;
  border-radius: 0.52083rem;
  background-clip: padding-box;
  overflow: hidden; }
  .product-contact-form-popup .popup-content {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1024px) {
      .product-contact-form-popup .popup-content {
        display: block; } }
  .product-contact-form-popup .img {
    border: 1px solid #E5E5E5; }
    @media (min-width: 1025px) {
      .product-contact-form-popup .img {
        max-width: 45.55556%;
        flex: 0 0 45.55556%;
        width: 100%; } }
    .product-contact-form-popup .img figure {
      aspect-ratio: 451 / 557; }
      @media (max-width: 1024px) {
        .product-contact-form-popup .img figure {
          aspect-ratio: 16 / 10; } }
      .product-contact-form-popup .img figure img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .product-contact-form-popup .product-contact-form {
    padding: 1.82292rem; }
    @media (min-width: 1025px) {
      .product-contact-form-popup .product-contact-form {
        max-width: 54.44444%;
        flex: 0 0 54.44444%;
        width: 100%; } }
    .product-contact-form-popup .product-contact-form .department {
      pointer-events: none; }

.product-detail-equipment-feature .feature-item {
  display: flex;
  flex-direction: column;
  gap: 0.36458rem;
  text-align: center; }
  .product-detail-equipment-feature .feature-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 999999px;
    background-color: #005BDB;
    transition: all 0.3s ease-in-out;
    margin: auto; }
    .product-detail-equipment-feature .feature-item:hover .icon {
      background-color: #E92429; }
    .product-detail-equipment-feature .feature-item .icon img {
      width: 50%; }
  .product-detail-equipment-feature .feature-item .text {
    font-size: 0.83333rem;
    line-height: 1.625; }

.feature-popup {
  width: 50.41667rem;
  padding: 0;
  border-radius: 0.52083rem;
  background-clip: padding-box;
  overflow: hidden; }
  .feature-popup .popup-content {
    display: flex;
    gap: 2.08333rem; }
    @media (max-width: 1024px) {
      .feature-popup .popup-content {
        flex-direction: column;
        gap: 1.5625rem; } }
  .feature-popup .img figure {
    aspect-ratio: 389 / 557; }
    .feature-popup .img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (min-width: 1025px) {
    .feature-popup .img {
      max-width: 40.18595%;
      flex: 0 0 40.18595%;
      width: 100%; } }
  @media (max-width: 1024px) {
    .feature-popup .img figure {
      aspect-ratio: 16 / 10; } }
  .feature-popup .content {
    flex: 1 1 auto;
    padding: 0 1.5625rem 1.5625rem; }
    @media (min-width: 1025px) {
      .feature-popup .content {
        padding: 5.20833rem 2.08333rem 2.08333rem 0; } }
  .feature-popup .article-content {
    font-size: 0.9375rem;
    line-height: 1.44444; }

.product-detail-other .swiper-container {
  padding: 0.52083rem;
  margin: -0.52083rem; }

.product-item {
  display: flex;
  flex-direction: column;
  padding: 1.35417rem;
  position: relative;
  background-color: #fff;
  border-radius: 0.26042rem;
  background-clip: padding-box;
  border: 1px solid #CCCCCC;
  transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .product-item:hover {
      border-color: #114088 !important; } }
  @media (max-width: 1024px) {
    .product-item {
      padding: 0.78125rem; } }
  .product-item .product-label {
    position: absolute;
    top: 1.04167rem;
    left: 1.04167rem;
    right: 1.04167rem;
    display: flex; }
    .product-item .product-label .label {
      display: inline-flex;
      width: 2.60417rem;
      height: 1.04167rem;
      border-radius: 0.20833rem;
      background-clip: padding-box;
      background-color: #E92429;
      color: #fff;
      font-size: 0.72917rem;
      align-items: center;
      justify-content: center;
      margin-left: 1.25rem;
      margin-left: auto; }
    .product-item .product-label .gift {
      width: 1.5625rem;
      height: 1.5625rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #005BDB;
      color: #fff;
      font-size: 0.83333rem; }
  .product-item .product-img figure {
    aspect-ratio: 279 / 222; }
    .product-item .product-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .product-item .product-title {
    margin-top: 1.04167rem;
    font-size: 1.04167rem;
    line-height: 1.2;
    color: #0044A5;
    font-weight: 700; }
  .product-item .product-desc {
    margin-top: 0.41667rem;
    font-size: 0.83333rem;
    line-height: 1.25;
    margin-bottom: 2.08333rem; }
    @media (max-width: 1024px) {
      .product-item .product-desc {
        margin-bottom: 0.625rem; } }
    .product-item .product-desc ul {
      list-style: disc;
      padding-left: 1.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.41667rem; }
  .product-item .product-btn {
    margin-top: auto; }
  @media (min-width: 1025px) {
    .product-item.is-row {
      display: flex;
      gap: 1.5625rem;
      flex-direction: row; } }

.product-slider .swiper-slide {
  height: auto; }

.product-slider .product-item {
  height: 100%; }

.product-item.is-equipment {
  border: 0;
  padding: 0;
  box-shadow: 0.3125rem 0.3125rem 0.52083rem rgba(0, 0, 0, 0.1);
  border-radius: 0.26042rem;
  background: #fff;
  overflow: hidden; }
  .product-item.is-equipment .product-img {
    background: #F2F7FF; }
    .product-item.is-equipment .product-img figure {
      aspect-ratio: 330 / 235; }
  .product-item.is-equipment .product-caption {
    padding: 1.35417rem;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .product-item.is-equipment .product-caption {
        padding: 0.78125rem; } }
  .product-item.is-equipment .product-title {
    margin-top: 0;
    margin-bottom: 0.9375rem; }
  .product-item.is-equipment .product-desc {
    margin-bottom: 1.5625rem; }
    @media (max-width: 768px) {
      .product-item.is-equipment .product-desc {
        margin-bottom: 0.78125rem; } }
  .product-item.is-equipment .product-subtitle {
    font-size: 0.83333rem;
    line-height: 1.375;
    font-weight: 700;
    margin-bottom: 0.52083rem; }
  .product-item.is-equipment .product-bottom {
    margin-top: auto; }
    @media (max-width: 768px) {
      .product-item.is-equipment .product-bottom {
        flex-direction: column;
        gap: 0.52083rem;
        align-items: flex-start !important; } }

.product-filter-list {
  display: flex;
  gap: 1.04167rem;
  flex-wrap: wrap; }
  @media (min-width: 1025px) {
    .product-filter-list {
      gap: 2.08333rem;
      justify-content: flex-end; } }

.product-filter-item {
  max-width: calc(33.333333% - 2.08333333rem);
  flex: 0 0 calc(33.333333% - 2.08333333rem);
  width: 100%;
  display: flex;
  gap: 0.72917rem;
  align-items: center; }
  @media (max-width: 1024px) {
    .product-filter-item {
      max-width: calc(50% - 1.04166666667rem);
      flex: 0 0 calc(50% - 1.04166666667rem);
      width: 100%;
      flex-direction: column;
      align-items: stretch; } }
  .product-filter-item .product-filter-title {
    font-weight: 600;
    font-size: 0.83333rem;
    line-height: 1.5; }
  .product-filter-item .product-filter-select {
    flex: 1; }
    .product-filter-item .product-filter-select select {
      font-weight: 600;
      font-size: 0.83333rem;
      line-height: 1.5;
      color: #999;
      background-color: #F5F5F5;
      height: 2.29167rem;
      border: 0;
      background-image: url("../img/sl-grey.png");
      background-repeat: no-repeat;
      background-position: 95% 50%; }
      @media (min-width: 1025px) {
        .product-filter-item .product-filter-select select {
          padding: 0 1.5625rem !important;
          background-position: 90% 50%; } }

.product-sub-nav-wrap {
  padding: 1.25rem 0 1.5625rem; }
  .product-sub-nav-wrap .product-sub-nav {
    display: flex;
    gap: 4.16667rem; }
    @media (min-width: 1025px) {
      .product-sub-nav-wrap .product-sub-nav {
        justify-content: center; } }
    @media (max-width: 768px) {
      .product-sub-nav-wrap .product-sub-nav {
        gap: 2.08333rem;
        overflow-x: auto;
        display: none;
        padding: 1rem;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); } }
    @media (max-width: 768px) {
      .product-sub-nav-wrap .product-sub-nav li {
        flex: 1;
        white-space: nowrap; }
        .product-sub-nav-wrap .product-sub-nav li:nth-child(n + 2) {
          margin-top: 0.83333rem; } }
    .product-sub-nav-wrap .product-sub-nav li a {
      text-transform: uppercase;
      color: #CCCCCC;
      position: relative;
      display: inline-block;
      padding-bottom: 0.72917rem;
      font-size: 0.9375rem;
      line-height: 1.33333;
      font-weight: 700; }
      .product-sub-nav-wrap .product-sub-nav li.active a {
        color: #114088; }
      .product-sub-nav-wrap .product-sub-nav li:hover a {
        color: #114088; }
      @media (max-width: 1024px) {
        .product-sub-nav-wrap .product-sub-nav li a {
          padding-bottom: 0.125rem; } }
      .product-sub-nav-wrap .product-sub-nav li a:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #005BDB;
        bottom: 0;
        left: 0;
        opacity: 0; }
        .product-sub-nav-wrap .product-sub-nav li.active a:before {
          opacity: 1; }

.product-sub-nav-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.83333rem;
  background-color: #114088;
  color: #fff; }
  @media (min-width: 769px) {
    .product-sub-nav-mobile {
      display: none; } }

.service-detail-heading {
  padding: 1.5625rem 0 0; }
  @media (min-width: 1025px) {
    .service-detail-heading {
      padding: 3.85417rem 0 0; } }

.service-detail-content-item:nth-child(even) {
  background-color: #F5F5F5; }
  @media (min-width: 1025px) {
    .service-detail-content-item:nth-child(even) .row {
      flex-direction: row-reverse; }
    .service-detail-content-item:nth-child(even) .content {
      padding-left: 3.125rem; } }

.service-detail-content-item:nth-child(odd) {
  background-color: #fff; }
  @media (min-width: 1025px) {
    .service-detail-content-item:nth-child(odd) .content {
      padding-right: 3.125rem; } }

@media (min-width: 1025px) {
  .service-detail-content-item .content {
    display: flex;
    flex-direction: column; } }

.service-detail-content-item .content ul {
  flex: 1 1 0px;
  overflow-y: auto;
  padding-right: 0.78125rem;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  height: 100%; }
  .service-detail-content-item .content ul li {
    display: flex; }
    .service-detail-content-item .content ul li:nth-child(n + 2) {
      margin-top: 0.625rem; }
    .service-detail-content-item .content ul li:before {
      font-family: 'Font Awesome 6 Pro';
      content: '\f061';
      color: #005BDB;
      padding-right: 0.52083rem; }

.service-detail-content-item .img {
  text-align: center; }
  .service-detail-content-item .img img {
    width: 100%; }
  @media (max-width: 1024px) {
    .service-detail-content-item .img {
      margin-top: 1.5625rem; } }

.service-detail-form {
  background-color: rgba(0, 91, 219, 0.05); }
  .service-detail-form .img figure {
    height: 100%; }
    .service-detail-form .img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media (max-width: 1024px) {
    .service-detail-form .service-form {
      margin-top: 1.5625rem; } }
  @media (min-width: 1025px) {
    .service-detail-form .service-form {
      padding-left: 3.125rem; } }

.shareholder-nav-item {
  position: relative;
  display: block; }
  .shareholder-nav-item:before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%);
    opacity: 0.5;
    z-index: 1;
    pointer-events: none; }
  .shareholder-nav-item .shareholder-nav-item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .shareholder-nav-item.is-big .shareholder-nav-item-img figure {
    aspect-ratio: 16 / 9; }
    @media (min-width: 1025px) {
      .shareholder-nav-item.is-big .shareholder-nav-item-img figure {
        aspect-ratio: 448 / 602; } }
  .shareholder-nav-item.is-small .shareholder-nav-item-img figure {
    aspect-ratio: 3 / 4; }
    @media (min-width: 1025px) {
      .shareholder-nav-item.is-small .shareholder-nav-item-img figure {
        aspect-ratio: 448 / 286; } }
  .shareholder-nav-item .shareholder-nav-item-title {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.61458rem 1.45833rem;
    z-index: 2;
    color: #fff; }
    @media (max-width: 1024px) {
      .shareholder-nav-item .shareholder-nav-item-title {
        padding: 0.78125rem; } }

.report-year-nav {
  display: flex;
  gap: 1.04167rem;
  flex-wrap: wrap; }
  @media (min-width: 1025px) {
    .report-year-nav {
      display: flex;
      gap: 5.05208rem;
      justify-content: center; } }
  .report-year-nav li a {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1;
    color: #999;
    position: relative;
    display: block;
    padding-bottom: 0.26042rem; }
    @media (min-width: 1025px) {
      .report-year-nav li a {
        padding-bottom: 0.83333rem; } }
    @media (min-width: 1025px) {
      .report-year-nav li a {
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 0.54167; } }
    .report-year-nav li a:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #005BDB;
      opacity: 0; }
    .report-year-nav li:hover a {
      color: #005BDB; }
      .report-year-nav li:hover a:before {
        opacity: 1; }
    .report-year-nav li.active a {
      color: #005BDB; }
      .report-year-nav li.active a:before {
        opacity: 1; }

@media (min-width: 1025px) {
  .report-list {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5625rem;
    margin: 0 -1.5625rem; } }

.report-item {
  min-height: 8.38542rem;
  background-color: #F5F5F5;
  padding: 0.83333rem 1.04167rem;
  display: flex;
  flex-direction: column; }
  .report-item .report-item-date {
    display: flex;
    gap: 0.78125rem;
    align-items: center;
    font-size: 0.72917rem;
    line-height: 1.28571;
    margin-bottom: 0.46875rem; }
    .report-item .report-item-date span {
      color: #005BDB;
      font-size: 1.04167rem;
      line-height: 1.3; }
  .report-item .report-item-title {
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.44444;
    margin-bottom: 0.83333rem; }
    .report-item:hover .report-item-title {
      text-decoration: underline; }
  .report-item .report-item-icon {
    margin-top: auto;
    font-size: 1.25rem;
    color: #005BDB;
    text-align: right; }

.article-content {
  line-height: 1.375;
  font-size: 0.83333rem;
  line-height: 1.25; }
  .article-content > * + * {
    margin-top: 0.83333rem; }
  .article-content ul {
    list-style: disc;
    padding-left: 1.25rem; }
  .article-content table td, .article-content table th {
    border: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top; }

.expand-content {
  overflow: hidden; }
  .expand-content.show {
    max-height: none !important; }

.scrollable-content {
  scrollbar-width: thin;
  overflow-y: auto; }
  .scrollable-content::-webkit-scrollbar {
    width: 1px;
    height: 1px; }
  .scrollable-content::-webkit-scrollbar-thumb {
    background: #005BDB; }
  .scrollable-content::-webkit-scrollbar-track {
    background: #fff; }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 2.29167rem;
  min-width: 7.8125rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s all;
  gap: 0.625rem;
  font-size: 0.72917rem; }
  .btn:hover, .btn:focus, .btn:active {
    text-decoration: none;
    outline: none; }

.btn-main {
  background-color: #005BDB;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main:hover {
      background-color: #0046a8; } }

.btn-border-main {
  border: 1px solid #005BDB;
  color: #005BDB; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main:hover {
      background-color: #005BDB;
      color: #fff; } }

.btn-blue {
  background-color: #114088;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue:hover {
      background-color: #0b2b5b; } }

.btn-border-blue {
  border: 1px solid #114088;
  color: #114088; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue:hover {
      background-color: #114088;
      color: #fff; } }

.btn-blue-2 {
  background-color: #0044A5;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue-2:hover {
      background-color: #002f72; } }

.btn-border-blue-2 {
  border: 1px solid #0044A5;
  color: #0044A5; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue-2:hover {
      background-color: #0044A5;
      color: #fff; } }

.btn-blue-light {
  background-color: #2086da;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue-light:hover {
      background-color: #196bae; } }

.btn-border-blue-light {
  border: 1px solid #2086da;
  color: #2086da; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue-light:hover {
      background-color: #2086da;
      color: #fff; } }

.btn-red {
  background-color: #E92429;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-red:hover {
      background-color: #c61418; } }

.btn-border-red {
  border: 1px solid #E92429;
  color: #E92429; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-red:hover {
      background-color: #E92429;
      color: #fff; } }

.btn-grey-half {
  background-color: #CCCCCC;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-half:hover {
      background-color: #b3b3b3; } }

.btn-border-grey-half {
  border: 1px solid #CCCCCC;
  color: #CCCCCC; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-half:hover {
      background-color: #CCCCCC;
      color: #333; } }

.btn-grey-light {
  background-color: #E5E5E5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-light:hover {
      background-color: #cccccc; } }

.btn-border-grey-light {
  border: 1px solid #E5E5E5;
  color: #E5E5E5; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-light:hover {
      background-color: #E5E5E5;
      color: #333; } }

.btn-grey-bright {
  background-color: #F5F5F5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-bright:hover {
      background-color: gainsboro; } }

.btn-border-grey-bright {
  border: 1px solid #F5F5F5;
  color: #F5F5F5; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-bright:hover {
      background-color: #F5F5F5;
      color: #333; } }

.btn-grey-999 {
  background-color: #999;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-999:hover {
      background-color: gray; } }

.btn-border-grey-999 {
  border: 1px solid #999;
  color: #999; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-999:hover {
      background-color: #999;
      color: #333; } }

.btn-white {
  background-color: #fff;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      background-color: #fff;
      color: #333; } }

.btn-body {
  background-color: #333;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-body:hover {
      background-color: #1a1a1a; } }

.btn-border-body {
  border: 1px solid #333;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-body:hover {
      background-color: #333;
      color: #fff; } }

.btn-black {
  background-color: #000;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-black:hover {
      background-color: black; } }

.btn-border-black {
  border: 1px solid #000;
  color: #000; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-black:hover {
      background-color: #000;
      color: #fff; } }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 999999px;
  color: #999;
  font-size: 1.66667rem;
  border: 1px solid #999;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all; }
  @media (max-width: 1024px) {
    .swiper-btn {
      display: none; } }
  @media (min-width: 1025px) {
    .swiper-btn {
      margin: 0 1.5625rem; }
      .swiper-btn.swiper-prev {
        right: 100%; }
      .swiper-btn.swiper-next {
        left: 100%; } }
  .swiper-btn-white {
    border-color: #fff;
    color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #E92429;
      color: #fff;
      border-color: #E92429; } }

.link-view-details {
  display: inline-flex;
  gap: 0.52083rem;
  font-size: 0.72917rem;
  font-weight: 700; }
  .link-view-details span {
    color: #E92429; }

.fancybox-button {
  background-color: #005BDB;
  width: 2.08333rem;
  height: 2.08333rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .fancybox-button svg {
    width: 1.5625rem;
    height: 1.5625rem; }
    .fancybox-button svg path {
      fill: white; }

.container {
  width: 100%;
  padding-right: 0.78125rem;
  padding-left: 0.78125rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 92vw; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 75rem; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 75rem; } }
  @media (min-width: 1601px) {
    .container {
      max-width: 75rem; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: 0.75rem; }

.fz-14 {
  font-size: 0.875rem; }

.fz-16 {
  font-size: 1rem; }

.fz-18 {
  font-size: 1.125rem; }

.fz-20 {
  font-size: 1.25rem; }

.fz-22 {
  font-size: 1.375rem; }

.fz-24 {
  font-size: 1.5rem; }

.fz-26 {
  font-size: 1.625rem; }

.fz-28 {
  font-size: 1.75rem; }

.fz-30 {
  font-size: 1.875rem; }

.fz-32 {
  font-size: 2rem; }

.fz-34 {
  font-size: 2.125rem; }

.fz-36 {
  font-size: 2.25rem; }

.fz-38 {
  font-size: 2.375rem; }

.fz-40 {
  font-size: 2.5rem; }

@media (min-width: 577px) {
  .fz-sm-12 {
    font-size: 0.75rem; }
  .fz-sm-14 {
    font-size: 0.875rem; }
  .fz-sm-16 {
    font-size: 1rem; }
  .fz-sm-18 {
    font-size: 1.125rem; }
  .fz-sm-20 {
    font-size: 1.25rem; }
  .fz-sm-22 {
    font-size: 1.375rem; }
  .fz-sm-24 {
    font-size: 1.5rem; }
  .fz-sm-26 {
    font-size: 1.625rem; }
  .fz-sm-28 {
    font-size: 1.75rem; }
  .fz-sm-30 {
    font-size: 1.875rem; }
  .fz-sm-32 {
    font-size: 2rem; }
  .fz-sm-34 {
    font-size: 2.125rem; }
  .fz-sm-36 {
    font-size: 2.25rem; }
  .fz-sm-38 {
    font-size: 2.375rem; }
  .fz-sm-40 {
    font-size: 2.5rem; } }

@media (min-width: 769px) {
  .fz-md-12 {
    font-size: 0.75rem; }
  .fz-md-14 {
    font-size: 0.875rem; }
  .fz-md-16 {
    font-size: 1rem; }
  .fz-md-18 {
    font-size: 1.125rem; }
  .fz-md-20 {
    font-size: 1.25rem; }
  .fz-md-22 {
    font-size: 1.375rem; }
  .fz-md-24 {
    font-size: 1.5rem; }
  .fz-md-26 {
    font-size: 1.625rem; }
  .fz-md-28 {
    font-size: 1.75rem; }
  .fz-md-30 {
    font-size: 1.875rem; }
  .fz-md-32 {
    font-size: 2rem; }
  .fz-md-34 {
    font-size: 2.125rem; }
  .fz-md-36 {
    font-size: 2.25rem; }
  .fz-md-38 {
    font-size: 2.375rem; }
  .fz-md-40 {
    font-size: 2.5rem; } }

@media (min-width: 1025px) {
  .fz-lg-12 {
    font-size: 0.75rem; }
  .fz-lg-14 {
    font-size: 0.875rem; }
  .fz-lg-16 {
    font-size: 1rem; }
  .fz-lg-18 {
    font-size: 1.125rem; }
  .fz-lg-20 {
    font-size: 1.25rem; }
  .fz-lg-22 {
    font-size: 1.375rem; }
  .fz-lg-24 {
    font-size: 1.5rem; }
  .fz-lg-26 {
    font-size: 1.625rem; }
  .fz-lg-28 {
    font-size: 1.75rem; }
  .fz-lg-30 {
    font-size: 1.875rem; }
  .fz-lg-32 {
    font-size: 2rem; }
  .fz-lg-34 {
    font-size: 2.125rem; }
  .fz-lg-36 {
    font-size: 2.25rem; }
  .fz-lg-38 {
    font-size: 2.375rem; }
  .fz-lg-40 {
    font-size: 2.5rem; } }

@media (min-width: 1281px) {
  .fz-xl-12 {
    font-size: 0.75rem; }
  .fz-xl-14 {
    font-size: 0.875rem; }
  .fz-xl-16 {
    font-size: 1rem; }
  .fz-xl-18 {
    font-size: 1.125rem; }
  .fz-xl-20 {
    font-size: 1.25rem; }
  .fz-xl-22 {
    font-size: 1.375rem; }
  .fz-xl-24 {
    font-size: 1.5rem; }
  .fz-xl-26 {
    font-size: 1.625rem; }
  .fz-xl-28 {
    font-size: 1.75rem; }
  .fz-xl-30 {
    font-size: 1.875rem; }
  .fz-xl-32 {
    font-size: 2rem; }
  .fz-xl-34 {
    font-size: 2.125rem; }
  .fz-xl-36 {
    font-size: 2.25rem; }
  .fz-xl-38 {
    font-size: 2.375rem; }
  .fz-xl-40 {
    font-size: 2.5rem; } }

@media (min-width: 1601px) {
  .fz-xxl-12 {
    font-size: 0.75rem; }
  .fz-xxl-14 {
    font-size: 0.875rem; }
  .fz-xxl-16 {
    font-size: 1rem; }
  .fz-xxl-18 {
    font-size: 1.125rem; }
  .fz-xxl-20 {
    font-size: 1.25rem; }
  .fz-xxl-22 {
    font-size: 1.375rem; }
  .fz-xxl-24 {
    font-size: 1.5rem; }
  .fz-xxl-26 {
    font-size: 1.625rem; }
  .fz-xxl-28 {
    font-size: 1.75rem; }
  .fz-xxl-30 {
    font-size: 1.875rem; }
  .fz-xxl-32 {
    font-size: 2rem; }
  .fz-xxl-34 {
    font-size: 2.125rem; }
  .fz-xxl-36 {
    font-size: 2.25rem; }
  .fz-xxl-38 {
    font-size: 2.375rem; }
  .fz-xxl-40 {
    font-size: 2.5rem; } }

.form-group input[type="text"], .form-group input[type="date"], .form-group select, .form-group textarea {
  background: #fff;
  border: 1px solid #E5E5E5;
  padding: 0 0.625rem !important;
  width: 100%;
  height: 2.08333rem;
  font-style: normal !important;
  font-size: 0.83333rem;
  line-height: 1.5;
  appearance: none; }

.form-group select {
  appearance: none;
  background-image: url("../img/sl.png");
  background-repeat: no-repeat;
  background-position: 97% 50%; }
  .form-group select option {
    color: #333; }

.form-group textarea {
  height: 7.8125rem;
  padding-top: 0.41667rem !important; }

.form-group .RadPicker {
  width: 100% !important;
  position: relative; }
  .form-group .RadPicker .rcCalPopup {
    position: absolute;
    right: 0.52083rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/calendar.png);
    background-position: center !important; }

.form-group.qradio ul {
  margin-bottom: -1.04167rem;
  display: flex;
  flex-wrap: wrap; }
  .form-group.qradio ul > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc( (100% - (2 - 1) * 1.04167rem) / 2);
    margin-bottom: 1.04167rem; }
    .form-group.qradio ul > *:not(:last-child) {
      margin-right: 1.04167rem; }
    .form-group.qradio ul > *:nth-child(2n) {
      margin-right: 0; }
  .form-group.qradio ul li input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    white-space: nowrap; }
    .form-group.qradio ul li input:checked ~ label {
      color: #114088; }
      .form-group.qradio ul li input:checked ~ label:before {
        content: '\f192'; }
  .form-group.qradio ul li label {
    display: flex; }
    .form-group.qradio ul li label:before {
      content: '\f111';
      font-family: "Font Awesome 6 Pro";
      margin-right: 0.52083rem;
      position: relative; }

.frm-btnwrap {
  margin-top: 1.5625rem; }

.frm-btn input[type="submit"] {
  transition: all 0.3s ease-in-out;
  background-color: #005BDB;
  color: #fff;
  border: 0;
  height: 2.08333rem;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  font-size: 0.9375rem;
  text-transform: uppercase;
  appearance: none; }

.frm-captcha {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 1.04167rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1.04167rem; }
  .frm-captcha label {
    display: none; }
  .frm-captcha .frm-captcha-input {
    flex: 1; }
  .frm-captcha .RadCaptcha {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    max-width: fit-content; }
    .frm-captcha .RadCaptcha img {
      height: 2.08333rem !important; }
    .frm-captcha .RadCaptcha > span {
      color: red;
      display: block;
      margin-top: 5px; }
    .frm-captcha .RadCaptcha > div > div {
      display: flex;
      align-items: center; }
    .frm-captcha .RadCaptcha a {
      font-size: 0;
      margin-left: 20px;
      display: none !important; }
      .frm-captcha .RadCaptcha a:before {
        font-family: "Font Awesome 6 Pro";
        content: '\f2ea';
        font-size: 1.40625rem;
        color: #333; }

.form-container .wrap-form .qradio .label {
  display: none; }

.form-container .wrap-form .frm-btn {
  height: 2.08333rem;
  width: 100%; }
  .form-container .wrap-form .frm-btn input[type="submit"] {
    width: 100%;
    min-width: auto; }

.form-container .wrap-form .frm-btnwrap {
  width: 9.375rem;
  margin-top: 0 !important; }
  @media (max-width: 576px) {
    .form-container .wrap-form .frm-btnwrap {
      width: 100%; } }

.wrap-form .row {
  margin-left: -1.04167rem;
  margin-bottom: -1.04167rem; }
  .wrap-form .row > * {
    padding-left: 1.04167rem;
    padding-bottom: 1.04167rem; }

@media (min-width: 1025px) {
  .page-piling-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 20; }
    .page-piling-container section {
      height: 100vh; } }

.fp-section {
  will-change: transform; }

.section-wrapper > .Module {
  height: 100%; }
  .section-wrapper > .Module > .ModuleContent {
    height: 100%; }

.img-zoom-out img {
  transition: all 0.3s ease-in-out; }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-out:hover img {
    transform: scale(1.05); } }

.img-zoom-in img {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05); }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-in:hover img {
    transform: scale(1); } }

.text-main {
  color: #005BDB; }

.text-blue {
  color: #114088; }

.text-blue-2 {
  color: #0044A5; }

.text-blue-light {
  color: #2086da; }

.text-red {
  color: #E92429; }

.text-grey-half {
  color: #CCCCCC; }

.text-grey-light {
  color: #E5E5E5; }

.text-grey-bright {
  color: #F5F5F5; }

.text-grey-999 {
  color: #999; }

.text-white {
  color: #fff; }

.text-body {
  color: #333; }

.text-black {
  color: #000; }

.background-main {
  background: #005BDB; }

.background-blue {
  background: #114088; }

.background-blue-2 {
  background: #0044A5; }

.background-blue-light {
  background: #2086da; }

.background-red {
  background: #E92429; }

.background-grey-half {
  background: #CCCCCC; }

.background-grey-light {
  background: #E5E5E5; }

.background-grey-bright {
  background: #F5F5F5; }

.background-grey-999 {
  background: #999; }

.background-white {
  background: #fff; }

.background-body {
  background: #333; }

.background-black {
  background: #000; }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.object-fit-img img {
  font-family: 'object-fit: cover'; }

.modulepager {
  margin-top: 1.5625rem; }
  .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 1.5625rem; }
      .modulepager .pagination li:first-child {
        margin-left: 0; }
      .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        background-color: #F5F5F5;
        width: 2.60417rem;
        height: 2.60417rem;
        color: #CCCCCC;
        font-size: 1.04167rem;
        border-radius: 50%;
        background-clip: padding-box;
        font-weight: 700; }
      .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #005BDB;
        color: #fff; }
      .modulepager .pagination li .FirstPage, .modulepager .pagination li .LastPage, .modulepager .pagination li .BackPage, .modulepager .pagination li .NextPage {
        display: none !important; }

.no-pagination .pages {
  display: none; }

.section-large {
  padding: 3.85417rem 0; }
  @media (max-width: 1024px) {
    .section-large {
      padding: 2.08333rem 0; } }

.section {
  padding: 3.125rem 0; }
  @media (max-width: 1024px) {
    .section {
      padding: 2.08333rem 0; } }

.section-small {
  padding: 2.08333rem 0; }

.section-desc {
  font-size: 0.9375rem;
  line-height: 1.33333; }

@media (max-width: 1024px) {
  .section-heading {
    flex-direction: column;
    gap: 1.04167rem; }
    .section-heading:before {
      display: none !important; } }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.25rem;
  : 0.25rem; }

.mt-2 {
  margin-top: 0.5rem;
  : 0.5rem; }

.mt-3 {
  margin-top: 0.75rem;
  : 0.75rem; }

.mt-4 {
  margin-top: 1rem;
  : 1rem; }

.mt-5 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-6 {
  margin-top: 1.5rem;
  : 1.5rem; }

.mt-7 {
  margin-top: 1.75rem;
  : 1.75rem; }

.mt-8 {
  margin-top: 2rem;
  : 2rem; }

.mt-9 {
  margin-top: 2.25rem;
  : 2.25rem; }

.mt-10 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-11 {
  margin-top: 2.75rem;
  : 2.75rem; }

.mt-12 {
  margin-top: 3rem;
  : 3rem; }

.mt-13 {
  margin-top: 3.25rem;
  : 3.25rem; }

.mt-14 {
  margin-top: 3.5rem;
  : 3.5rem; }

.mt-15 {
  margin-top: 3.75rem;
  : 3.75rem; }

.mt-16 {
  margin-top: 4rem;
  : 4rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.25rem;
  : 0.25rem; }

.mr-2 {
  margin-right: 0.5rem;
  : 0.5rem; }

.mr-3 {
  margin-right: 0.75rem;
  : 0.75rem; }

.mr-4 {
  margin-right: 1rem;
  : 1rem; }

.mr-5 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-6 {
  margin-right: 1.5rem;
  : 1.5rem; }

.mr-7 {
  margin-right: 1.75rem;
  : 1.75rem; }

.mr-8 {
  margin-right: 2rem;
  : 2rem; }

.mr-9 {
  margin-right: 2.25rem;
  : 2.25rem; }

.mr-10 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-11 {
  margin-right: 2.75rem;
  : 2.75rem; }

.mr-12 {
  margin-right: 3rem;
  : 3rem; }

.mr-13 {
  margin-right: 3.25rem;
  : 3.25rem; }

.mr-14 {
  margin-right: 3.5rem;
  : 3.5rem; }

.mr-15 {
  margin-right: 3.75rem;
  : 3.75rem; }

.mr-16 {
  margin-right: 4rem;
  : 4rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.25rem;
  : 0.25rem; }

.mb-2 {
  margin-bottom: 0.5rem;
  : 0.5rem; }

.mb-3 {
  margin-bottom: 0.75rem;
  : 0.75rem; }

.mb-4 {
  margin-bottom: 1rem;
  : 1rem; }

.mb-5 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-6 {
  margin-bottom: 1.5rem;
  : 1.5rem; }

.mb-7 {
  margin-bottom: 1.75rem;
  : 1.75rem; }

.mb-8 {
  margin-bottom: 2rem;
  : 2rem; }

.mb-9 {
  margin-bottom: 2.25rem;
  : 2.25rem; }

.mb-10 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-11 {
  margin-bottom: 2.75rem;
  : 2.75rem; }

.mb-12 {
  margin-bottom: 3rem;
  : 3rem; }

.mb-13 {
  margin-bottom: 3.25rem;
  : 3.25rem; }

.mb-14 {
  margin-bottom: 3.5rem;
  : 3.5rem; }

.mb-15 {
  margin-bottom: 3.75rem;
  : 3.75rem; }

.mb-16 {
  margin-bottom: 4rem;
  : 4rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.25rem;
  : 0.25rem; }

.ml-2 {
  margin-left: 0.5rem;
  : 0.5rem; }

.ml-3 {
  margin-left: 0.75rem;
  : 0.75rem; }

.ml-4 {
  margin-left: 1rem;
  : 1rem; }

.ml-5 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-6 {
  margin-left: 1.5rem;
  : 1.5rem; }

.ml-7 {
  margin-left: 1.75rem;
  : 1.75rem; }

.ml-8 {
  margin-left: 2rem;
  : 2rem; }

.ml-9 {
  margin-left: 2.25rem;
  : 2.25rem; }

.ml-10 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-11 {
  margin-left: 2.75rem;
  : 2.75rem; }

.ml-12 {
  margin-left: 3rem;
  : 3rem; }

.ml-13 {
  margin-left: 3.25rem;
  : 3.25rem; }

.ml-14 {
  margin-left: 3.5rem;
  : 3.5rem; }

.ml-15 {
  margin-left: 3.75rem;
  : 3.75rem; }

.ml-16 {
  margin-left: 4rem;
  : 4rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.25rem;
  : 0.25rem; }

.m-2 {
  margin: 0.5rem;
  : 0.5rem; }

.m-3 {
  margin: 0.75rem;
  : 0.75rem; }

.m-4 {
  margin: 1rem;
  : 1rem; }

.m-5 {
  margin: 1.25rem;
  : 1.25rem; }

.m-6 {
  margin: 1.5rem;
  : 1.5rem; }

.m-7 {
  margin: 1.75rem;
  : 1.75rem; }

.m-8 {
  margin: 2rem;
  : 2rem; }

.m-9 {
  margin: 2.25rem;
  : 2.25rem; }

.m-10 {
  margin: 2.5rem;
  : 2.5rem; }

.m-11 {
  margin: 2.75rem;
  : 2.75rem; }

.m-12 {
  margin: 3rem;
  : 3rem; }

.m-13 {
  margin: 3.25rem;
  : 3.25rem; }

.m-14 {
  margin: 3.5rem;
  : 3.5rem; }

.m-15 {
  margin: 3.75rem;
  : 3.75rem; }

.m-16 {
  margin: 4rem;
  : 4rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.25rem;
  : 0.25rem; }

.pt-2 {
  padding-top: 0.5rem;
  : 0.5rem; }

.pt-3 {
  padding-top: 0.75rem;
  : 0.75rem; }

.pt-4 {
  padding-top: 1rem;
  : 1rem; }

.pt-5 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-6 {
  padding-top: 1.5rem;
  : 1.5rem; }

.pt-7 {
  padding-top: 1.75rem;
  : 1.75rem; }

.pt-8 {
  padding-top: 2rem;
  : 2rem; }

.pt-9 {
  padding-top: 2.25rem;
  : 2.25rem; }

.pt-10 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-11 {
  padding-top: 2.75rem;
  : 2.75rem; }

.pt-12 {
  padding-top: 3rem;
  : 3rem; }

.pt-13 {
  padding-top: 3.25rem;
  : 3.25rem; }

.pt-14 {
  padding-top: 3.5rem;
  : 3.5rem; }

.pt-15 {
  padding-top: 3.75rem;
  : 3.75rem; }

.pt-16 {
  padding-top: 4rem;
  : 4rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.25rem;
  : 0.25rem; }

.pr-2 {
  padding-right: 0.5rem;
  : 0.5rem; }

.pr-3 {
  padding-right: 0.75rem;
  : 0.75rem; }

.pr-4 {
  padding-right: 1rem;
  : 1rem; }

.pr-5 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-6 {
  padding-right: 1.5rem;
  : 1.5rem; }

.pr-7 {
  padding-right: 1.75rem;
  : 1.75rem; }

.pr-8 {
  padding-right: 2rem;
  : 2rem; }

.pr-9 {
  padding-right: 2.25rem;
  : 2.25rem; }

.pr-10 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-11 {
  padding-right: 2.75rem;
  : 2.75rem; }

.pr-12 {
  padding-right: 3rem;
  : 3rem; }

.pr-13 {
  padding-right: 3.25rem;
  : 3.25rem; }

.pr-14 {
  padding-right: 3.5rem;
  : 3.5rem; }

.pr-15 {
  padding-right: 3.75rem;
  : 3.75rem; }

.pr-16 {
  padding-right: 4rem;
  : 4rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.25rem;
  : 0.25rem; }

.pb-2 {
  padding-bottom: 0.5rem;
  : 0.5rem; }

.pb-3 {
  padding-bottom: 0.75rem;
  : 0.75rem; }

.pb-4 {
  padding-bottom: 1rem;
  : 1rem; }

.pb-5 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-6 {
  padding-bottom: 1.5rem;
  : 1.5rem; }

.pb-7 {
  padding-bottom: 1.75rem;
  : 1.75rem; }

.pb-8 {
  padding-bottom: 2rem;
  : 2rem; }

.pb-9 {
  padding-bottom: 2.25rem;
  : 2.25rem; }

.pb-10 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-11 {
  padding-bottom: 2.75rem;
  : 2.75rem; }

.pb-12 {
  padding-bottom: 3rem;
  : 3rem; }

.pb-13 {
  padding-bottom: 3.25rem;
  : 3.25rem; }

.pb-14 {
  padding-bottom: 3.5rem;
  : 3.5rem; }

.pb-15 {
  padding-bottom: 3.75rem;
  : 3.75rem; }

.pb-16 {
  padding-bottom: 4rem;
  : 4rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.25rem;
  : 0.25rem; }

.pl-2 {
  padding-left: 0.5rem;
  : 0.5rem; }

.pl-3 {
  padding-left: 0.75rem;
  : 0.75rem; }

.pl-4 {
  padding-left: 1rem;
  : 1rem; }

.pl-5 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-6 {
  padding-left: 1.5rem;
  : 1.5rem; }

.pl-7 {
  padding-left: 1.75rem;
  : 1.75rem; }

.pl-8 {
  padding-left: 2rem;
  : 2rem; }

.pl-9 {
  padding-left: 2.25rem;
  : 2.25rem; }

.pl-10 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-11 {
  padding-left: 2.75rem;
  : 2.75rem; }

.pl-12 {
  padding-left: 3rem;
  : 3rem; }

.pl-13 {
  padding-left: 3.25rem;
  : 3.25rem; }

.pl-14 {
  padding-left: 3.5rem;
  : 3.5rem; }

.pl-15 {
  padding-left: 3.75rem;
  : 3.75rem; }

.pl-16 {
  padding-left: 4rem;
  : 4rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.25rem;
  : 0.25rem; }

.p-2 {
  padding: 0.5rem;
  : 0.5rem; }

.p-3 {
  padding: 0.75rem;
  : 0.75rem; }

.p-4 {
  padding: 1rem;
  : 1rem; }

.p-5 {
  padding: 1.25rem;
  : 1.25rem; }

.p-6 {
  padding: 1.5rem;
  : 1.5rem; }

.p-7 {
  padding: 1.75rem;
  : 1.75rem; }

.p-8 {
  padding: 2rem;
  : 2rem; }

.p-9 {
  padding: 2.25rem;
  : 2.25rem; }

.p-10 {
  padding: 2.5rem;
  : 2.5rem; }

.p-11 {
  padding: 2.75rem;
  : 2.75rem; }

.p-12 {
  padding: 3rem;
  : 3rem; }

.p-13 {
  padding: 3.25rem;
  : 3.25rem; }

.p-14 {
  padding: 3.5rem;
  : 3.5rem; }

.p-15 {
  padding: 3.75rem;
  : 3.75rem; }

.p-16 {
  padding: 4rem;
  : 4rem; }

@media (min-width: 577px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-sm-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-sm-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-sm-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-sm-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-sm-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-sm-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-sm-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-sm-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-sm-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-sm-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-sm-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-sm-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-sm-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-sm-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-sm-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-sm-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-sm-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-sm-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-sm-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-sm-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-sm-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-sm-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-sm-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-sm-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-sm-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-sm-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-sm-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-sm-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-sm-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-sm-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-sm-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-sm-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-sm-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-sm-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-sm-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-sm-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-sm-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-sm-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-sm-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-sm-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-sm-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-sm-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-sm-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-sm-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-sm-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-sm-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-sm-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-sm-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-sm-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-sm-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-sm-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-sm-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-sm-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-sm-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-sm-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-sm-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-sm-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-sm-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-sm-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-sm-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-sm-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-sm-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-sm-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-sm-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-sm-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-sm-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-sm-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-sm-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-sm-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-sm-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-sm-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-sm-4 {
    padding: 1rem;
    : 1rem; }
  .p-sm-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-sm-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-sm-8 {
    padding: 2rem;
    : 2rem; }
  .p-sm-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-sm-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-sm-12 {
    padding: 3rem;
    : 3rem; }
  .p-sm-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-sm-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-sm-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-sm-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 577px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-sm-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-sm-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-sm-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-sm-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-sm-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-sm-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-sm-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-sm-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-sm-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-sm-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-sm-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-sm-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-sm-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-sm-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-sm-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-sm-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-sm-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-sm-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-sm-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-sm-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-sm-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-sm-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-sm-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-sm-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-sm-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-sm-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-sm-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-sm-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-sm-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-sm-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-sm-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-sm-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-sm-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-sm-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-sm-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-sm-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-sm-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-sm-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-sm-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-sm-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-sm-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-sm-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-sm-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-sm-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-sm-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-sm-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-sm-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-sm-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-sm-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-sm-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-sm-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-sm-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-sm-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-sm-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-sm-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-sm-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-sm-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-sm-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-sm-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-sm-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-sm-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-sm-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-sm-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-sm-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-sm-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-sm-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-sm-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-sm-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-sm-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-sm-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-sm-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-sm-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-sm-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-sm-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-sm-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-sm-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-sm-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-sm-4 {
    margin: 1rem;
    : 1rem; }
  .m-sm-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-sm-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-sm-8 {
    margin: 2rem;
    : 2rem; }
  .m-sm-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-sm-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-sm-12 {
    margin: 3rem;
    : 3rem; }
  .m-sm-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-sm-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-sm-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-sm-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 769px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-md-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-md-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-md-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-md-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-md-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-md-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-md-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-md-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-md-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-md-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-md-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-md-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-md-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-md-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-md-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-md-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-md-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-md-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-md-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-md-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-md-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-md-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-md-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-md-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-md-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-md-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-md-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-md-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-md-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-md-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-md-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-md-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-md-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-md-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-md-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-md-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-md-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-md-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-md-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-md-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-md-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-md-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-md-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-md-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-md-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-md-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-md-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-md-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-md-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-md-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-md-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-md-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-md-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-md-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-md-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-md-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-md-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-md-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-md-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-md-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-md-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-md-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-md-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-md-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-md-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-md-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-md-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-md-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-md-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-md-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-md-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-md-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-md-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-md-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-md-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-md-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-md-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-md-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-md-4 {
    padding: 1rem;
    : 1rem; }
  .p-md-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-md-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-md-8 {
    padding: 2rem;
    : 2rem; }
  .p-md-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-md-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-md-12 {
    padding: 3rem;
    : 3rem; }
  .p-md-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-md-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-md-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-md-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-md-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-md-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-md-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-md-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-md-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-md-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-md-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-md-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-md-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-md-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-md-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-md-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-md-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-md-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-md-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-md-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-md-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-md-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-md-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-md-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-md-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-md-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-md-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-md-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-md-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-md-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-md-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-md-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-md-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-md-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-md-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-md-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-md-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-md-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-md-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-md-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-md-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-md-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-md-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-md-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-md-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-md-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-md-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-md-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-md-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-md-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-md-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-md-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-md-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-md-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-md-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-md-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-md-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-md-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-md-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-md-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-md-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-md-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-md-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-md-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-md-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-md-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-md-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-md-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-md-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-md-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-md-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-md-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-md-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-md-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-md-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-md-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-md-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-md-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-md-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-md-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-md-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-md-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-md-4 {
    margin: 1rem;
    : 1rem; }
  .m-md-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-md-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-md-8 {
    margin: 2rem;
    : 2rem; }
  .m-md-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-md-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-md-12 {
    margin: 3rem;
    : 3rem; }
  .m-md-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-md-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-md-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-md-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1025px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-lg-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-lg-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-lg-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-lg-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-lg-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-lg-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-lg-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-lg-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-lg-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-lg-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-lg-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-lg-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-lg-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-lg-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-lg-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-lg-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-lg-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-lg-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-lg-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-lg-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-lg-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-lg-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-lg-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-lg-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-lg-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-lg-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-lg-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-lg-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-lg-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-lg-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-lg-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-lg-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-lg-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-lg-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-lg-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-lg-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-lg-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-lg-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-lg-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-lg-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-lg-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-lg-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-lg-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-lg-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-lg-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-lg-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-lg-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-lg-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-lg-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-lg-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-lg-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-lg-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-lg-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-lg-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-lg-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-lg-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-lg-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-lg-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-lg-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-lg-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-lg-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-lg-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-lg-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-lg-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-lg-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-lg-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-lg-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-lg-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-lg-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-lg-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-lg-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-lg-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-lg-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-lg-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-lg-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-lg-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-lg-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-lg-4 {
    padding: 1rem;
    : 1rem; }
  .p-lg-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-lg-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-lg-8 {
    padding: 2rem;
    : 2rem; }
  .p-lg-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-lg-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-lg-12 {
    padding: 3rem;
    : 3rem; }
  .p-lg-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-lg-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-lg-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-lg-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1025px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-lg-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-lg-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-lg-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-lg-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-lg-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-lg-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-lg-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-lg-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-lg-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-lg-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-lg-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-lg-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-lg-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-lg-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-lg-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-lg-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-lg-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-lg-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-lg-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-lg-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-lg-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-lg-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-lg-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-lg-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-lg-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-lg-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-lg-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-lg-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-lg-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-lg-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-lg-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-lg-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-lg-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-lg-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-lg-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-lg-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-lg-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-lg-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-lg-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-lg-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-lg-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-lg-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-lg-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-lg-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-lg-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-lg-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-lg-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-lg-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-lg-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-lg-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-lg-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-lg-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-lg-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-lg-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-lg-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-lg-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-lg-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-lg-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-lg-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-lg-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-lg-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-lg-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-lg-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-lg-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-lg-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-lg-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-lg-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-lg-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-lg-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-lg-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-lg-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-lg-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-lg-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-lg-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-lg-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-lg-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-lg-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-lg-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-lg-4 {
    margin: 1rem;
    : 1rem; }
  .m-lg-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-lg-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-lg-8 {
    margin: 2rem;
    : 2rem; }
  .m-lg-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-lg-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-lg-12 {
    margin: 3rem;
    : 3rem; }
  .m-lg-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-lg-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-lg-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-lg-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1281px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1281px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xl-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1601px) {
  .pt-xxl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xxl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xxl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xxl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xxl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xxl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xxl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xxl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xxl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xxl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xxl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xxl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xxl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xxl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xxl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xxl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xxl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xxl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xxl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xxl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xxl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xxl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xxl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xxl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xxl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xxl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xxl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xxl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xxl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xxl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xxl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xxl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xxl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xxl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xxl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xxl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xxl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xxl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xxl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xxl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xxl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xxl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xxl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xxl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xxl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xxl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xxl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xxl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xxl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xxl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xxl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xxl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xxl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xxl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xxl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xxl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xxl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xxl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xxl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xxl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xxl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xxl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xxl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xxl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xxl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xxl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xxl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xxl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xxl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xxl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xxl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xxl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xxl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xxl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xxl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xxl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xxl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xxl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xxl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xxl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xxl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xxl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xxl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xxl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xxl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xxl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xxl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xxl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xxl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xxl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xxl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xxl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xxl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xxl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xxl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xxl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xxl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xxl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xxl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xxl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xxl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xxl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xxl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xxl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xxl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xxl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xxl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xxl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xxl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xxl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xxl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xxl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xxl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xxl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xxl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1601px) {
  .mt-xxl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xxl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xxl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xxl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xxl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xxl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xxl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xxl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xxl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xxl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xxl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xxl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xxl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xxl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xxl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xxl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xxl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xxl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xxl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xxl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xxl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xxl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xxl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xxl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xxl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xxl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xxl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xxl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xxl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xxl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xxl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xxl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xxl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xxl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xxl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xxl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xxl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xxl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xxl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xxl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xxl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xxl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xxl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xxl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xxl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xxl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xxl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xxl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xxl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xxl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xxl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xxl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xxl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xxl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xxl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xxl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xxl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xxl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xxl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xxl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xxl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xxl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xxl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xxl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xxl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xxl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xxl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xxl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xxl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xxl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xxl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xxl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xxl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xxl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xxl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xxl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xxl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xxl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xxl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xxl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xxl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xxl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xxl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xxl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xxl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xxl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xxl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xxl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xxl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xxl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xxl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xxl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xxl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xxl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xxl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xxl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xxl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xxl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xxl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xxl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xxl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xxl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xxl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xxl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xxl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xxl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xxl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xxl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xxl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xxl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xxl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xxl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xxl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xxl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xxl-16 {
    margin: 4rem;
    : 4rem; } }

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto; }
    .table-responsive table {
      width: 150vw; } }

.has-divider {
  position: relative;
  padding-bottom: 1.04167rem; }
  @media (min-width: 1025px) {
    .has-divider {
      padding-bottom: 1.5625rem; } }
  .has-divider:before {
    content: "";
    display: block;
    position: absolute;
    width: 2.60417rem;
    height: 1px;
    bottom: 0;
    background-color: #005BDB; }
    .text-white.has-divider:before {
      background-color: #fff; }
    .text-center.has-divider:before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }

.section-title, h1 {
  font-weight: 600;
  font-size: 2.29167rem;
  line-height: 1.18182; }

.sub-title, .tabs-nav li a {
  font-size: 1.04167rem;
  line-height: 1.3; }

.text-normal {
  font-size: 0.83333rem;
  line-height: 1.25; }

.text-heading-4 {
  font-weight: 700;
  font-size: 1.66667rem;
  line-height: 1.375; }

.text-heading-5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.375; }

.text-heading-6 {
  font-weight: 700;
  font-size: 1.04167rem;
  line-height: 1.2; }

.text-heading-7, footer .footer-info h3 {
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.33333; }

.text-heading-8 {
  font-weight: 700;
  font-size: 0.83333rem;
  line-height: 1.375; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.375; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@media (max-width: 1024px) {
  .service-cate-bg {
    padding: 2.08333rem 0; } }

@media (min-width: 1025px) {
  .service-cate-bg {
    display: flex;
    align-items: center; } }

.service-cate-1 .sub-cate-list {
  margin-top: 1.92708rem; }
  @media (max-width: 1024px) {
    .service-cate-1 .sub-cate-list {
      margin-top: 1.25rem; } }
  .service-cate-1 .sub-cate-list ul li {
    display: flex;
    gap: 0.78125rem;
    align-items: center;
    font-size: 1.25rem;
    line-height: 1.375;
    margin-bottom: 0.78125rem;
    padding-bottom: 0.78125rem;
    border-bottom: 1px solid #0044A5; }

.service-cate-1 .btn {
  margin-top: 0.78125rem; }

@media (min-width: 1025px) {
  .service-cate-1 .cate-img figure {
    aspect-ratio: 970 / 577; }
    .service-cate-1 .cate-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }

@media (min-width: 1025px) {
  .service-cate-2 .cate-img figure {
    aspect-ratio: 970 / 577; }
    .service-cate-2 .cate-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }

@media (min-width: 1025px) {
  .service-cate-3 .cate-img figure {
    aspect-ratio: 830 / 570; }
    .service-cate-3 .cate-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }

@media (min-width: 1025px) {
  .service-cate-4 .cate-img figure {
    aspect-ratio: 782 / 739; }
    .service-cate-4 .cate-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }

@media (min-width: 1025px) {
  .service-cate-4 .cate-content {
    padding-right: 2.5rem; } }

@media (max-width: 1024px) {
  .service-cate-4 .cate-sub-img {
    display: none; } }

@media (min-width: 1025px) {
  .service-cate-4 .cate-sub-img figure {
    width: 20.83333rem;
    aspect-ratio: 400 / 227;
    margin-left: auto; }
    .service-cate-4 .cate-sub-img figure img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }
