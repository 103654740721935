.product-item
	display: flex
	flex-direction: column
	padding: r(26px)
	position: relative
	+bg(white)
	+radius(r(5px))
	+border($color: grey-half)
	+trans-all
	+on-hover
		border-color: color(blue) !important
	+mq-max(lg)
		padding: r(15px)
	.product-label
		position: absolute
		top: r(20px)
		left: r(20px)
		right: r(20px)
		display: flex
		.label
			display: inline-flex
			+box(r(50px),r(20px))
			+radius(r(4px))
			+bg(red)
			+text(white)
			font-size: r(14px)
			align-items: center
			justify-content: center
			margin-left: r(24px)
			margin-left: auto
		.gift
			+circle(r(30px))
			display: flex
			+flex-center
			+bg(main)
			+text(white)
			font-size: r(16px)
	.product-img
		figure
			aspect-ratio: 279 / 222 
			img
				+fit-contain
	.product-title
		margin-top: r(20px)
		+fz-lh(20px,24px)
		+text(blue-2)
		font-weight: 700
	.product-desc
		margin-top: r(8px)
		+fz-lh(16px, 20px)
		margin-bottom: r(40px)
		+mq-max(lg)
			margin-bottom: r(12px)
		ul
			list-style: disc
			padding-left: 1.25rem
			+flex-gap-col(r(8px))
	.product-btn
		margin-top: auto
	&.is-row
		+mq-min(lg)
			+flex-gap(r(30px))
			flex-direction: row

.product-slider
	.swiper-slide
		height: auto
	.product-item
		height: 100%