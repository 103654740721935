//Reset
@import src/_plugins/sass-libraries/reset.sass
@import src/_plugins/sass-libraries/syntax.scss
@import src/_plugins/sass-libraries/util.scss
// @import src/_plugins/sass-libraries/fluid-type.sass
@import src/_plugins/sass-libraries/family.scss
@import src/_plugins/sass-libraries/columnizer.scss
@import src/_plugins/sass-libraries/svg-background.scss

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	line-height: 1
	font-family: 'Manrope', sans-serif
	color: #333
	font-size: 3.6vw
	+mq-min(md)
		font-size: 1.5vw
	+mq-min(lg)
		font-size: 1vw

.fullpage
	+mq-min(lg)
		overflow: hidden
	+mq-max(lg)
		main
			padding-top: r(100px)

body:not(.fullpage)
	main
		padding-top: r(100px)

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Manrope', sans-serif
	&:before
		font-family: 'Font Awesome 6 Pro'

.frm-btn-reset
	display: none !important

.frm-btnwrap
	.label
		display: none

.section-fp-padding-top
	+mq-min(lg)
		padding-top: r(100px)
		height: 100%