.home-statistic
	.section-title
		// +mq-min(lg)
		// 	transform: translateY(20px)
		// 	opacity: 0
	.home-statistic-bg
		+mq-max(lg)
			background: none !important
			padding: r(40px) 0
		+mq-min(lg)
			// transform: scale(1.15)
			// opacity: 0
			display: flex
			align-items: center
	.home-statistic-wrapper
		+mq-min(lg)
			// padding-top: r(90px)
			width: 100%
	.stat-item
		margin: 0 auto
		border: 1px solid rgba(#d6d6d6 , 0.5)
		display: flex
		+flex-center
		+circle(r(170px))
		+mq-min(lg)
			border: 1px solid rgba(#fff , 0.5)
			+circle(r(200px))
			padding: r(10px)
			+trans-all
			// opacity: 0
			// transform: translateY(20px)
		.stat-item-inner
			background: gradient(blue)
			display: flex
			flex-direction: column
			+flex-center
			text-align: center
			padding: 0 r(25px)
			+text(white)
			+circle(r(150px))
			+mq-min(lg)
				+circle(r(180px))
				+trans-all
			.stat-num,.stat-desc
				+trans-all
			.stat-num
				+font(800, 56px, 76px)
				+mq-max(lg)
					+fz-lh(48px, 48px)
		+on-hover
			transform: scale(1.2)
			.stat-item-inner
				background: gradient(red)
				.stat-num,.stat-desc
					transform: scale(0.9)