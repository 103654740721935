.partner-item
	+mq-min(lg)
		max-width: r(200px)
		margin: 0 auto
	.partner-logo
		background: linear-gradient(-135deg, #06529E 100%, #2AA8E0 100%)
		padding: r(4px)
		+radius(r(10px))
		overflow: hidden
		+mq-min(lg)
			height: r(100px)
		+mq-max(lg)
			aspect-ratio: 196 / 100
		figure
			+img-scale(48.02041%)
			+radius(r(6px))
			height: 100%
			overflow: hidden
			+bg(white)
			position: relative
			img
				position: absolute
				+center(both)
				height: 100%
	.partner-name
		text-align: center
		margin-top: r(15px)
		+text(white)
		+fz-lh(18px, 26px)
