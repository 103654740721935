.home-products
	.section-wrapper
		position: relative
		z-index: 1
		+mq-min(lg)
			height: 100%
			.row
				height: 100%

	.section-title
		+mq-min(lg)
			position: absolute
			top: r(160px)
			+center(X)
			z-index: 2
		+mq-max(lg)
			color: color(main) !important
			&:before
				background: color(main) !important

	.product-nav-col
		position: relative
		z-index: 1
		+on-hover
			z-index: 4
			.product-nav-item
				background-image: none !important

	.product-nav-list
		height: 100%

	.product-nav-hover-list
		position: absolute
		+fill
		pointer-events: none

	.product-nav-hover-item
		position: absolute
		+fill
		z-index: 1
		opacity: 0
		+trans-all
		will-change: opacity, transform
		&:before
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))
			content: ''
			+fill
		.product-nav-hover-content
			display: flex
			align-items: center
			height: 100%
			+fz-lh(32px, 44px)
			position: relative
			z-index: 2
			+text(white)
			padding: 0 r(90px)
			transform: translateX(-50%)
			opacity: 0
			+trans-all
		&.show
			opacity: 1
			z-index: 3
			.product-nav-hover-content
				opacity: 1
				transform: none

	.product-nav-item
		position: relative
		display: block
		+text(white)
		+mq-max(lg)
			padding: r(40px) r(20px)
			height: 100%
		+mq-min(lg)
			height: 100%
			// transform: translateY(-50%)
			// opacity: 0
			// transform-origin: center
		&:before
			+pseudo($width: 100%, $height: 100%)
				top: 0
				left: 0
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))
				z-index: 1
		.product-nav-inner
			position: relative
			z-index: 2
			+mq-min(lg)
				height: r(230px)
		.product-nav-button
			position: absolute
			+center(X)
			bottom: r(200px)
			z-index: 2
			opacity: 0
			+trans-all
			+parent-state(':hover')
				opacity: 1
			+remove(lg,max)
		.product-nav-wrapper
			height: 100%
			width: 100%
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
		.product-nav-icon
			+circle(r(140px))
			margin: 0 auto
			border: 1px solid #fff
			display: flex
			+flex-center
			font-size: r(64px)
		.product-nav-title
			text-align: center
			margin-top: r(10px)
