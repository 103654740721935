.home-shareholder
	.Module
		height: auto !important
		width: 100%
		.ModuleContent
			height: 100%
	.home-shareholder-bg
		+mq-min(lg)
			display: flex
			align-items: center
		+mq-max(lg)
			background: #fdfdfd !important
			padding: r(40px) 0
	.home-shareholder-wrapper
		+mq-min(lg)
			width: 100%

	.section-title
		+mq-max(lg)
			+text(blue-2)

	.home-shareholder-tabs
		display: flex
		align-items: center
		+mq-min(lg)
			justify-content: flex-end

.shareholder-news-item
	display: block
	.shareholder-news-title
		justify-content: space-between
		align-items: center
		display: flex
	.shareholder-news-img
		figure
			img
				+fit-cover

	&.shareholder-news-item-big
		+mq-min(lg)
			aspect-ratio: 690 / 530
		.shareholder-news-img
			figure
				aspect-ratio: 690 / 430
		.shareholder-news-title
			+mq-min(lg)
				aspect-ratio: 690 / 100
			+bg(white)
			+text(blue-2)
			+font(600, 24px, 30px)
			padding: r(20px) r(30px)
			+mq-max(lg)
				padding: r(15px)
			h3
				padding-right: r(50px)
				+mq-min(lg)
					+line(1)
	&.shareholder-news-item-small
		aspect-ratio: 330 / 250
		position: relative
		&:before
			pointer-events: none
			+pseudo
				+fill
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))
		.shareholder-news-img
			height: 100%
			figure
				height: 100%
		.shareholder-news-title 
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			padding: r(16px) r(30px)
			z-index: 2
			+text(white)
			+font(600, 20px, 25px)
			+mq-max(lg)
				padding: r(15px)
			h3
				+line(2)
				padding-right: 1rem
