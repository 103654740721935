.contact-info
	table
		width: 100%
		+fz-lh(16px, 20px)
		th,td
			padding: r(10px) r(15px)
			text-align: left
		th,td:first-child
			font-weight: 600
		th
			white-space: nowrap
		td
			&:first-child
				padding-left: r(30px)
		tr
			+odd
				background: #F5F5F5