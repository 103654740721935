.home-service
	+mq-max(lg)
		padding: r(40px) 0
	.section-wrapper
		+mq-min(lg)
			padding-bottom: r(30px)
			display: flex
			align-items: center
			height: 100%
	.home-service-wrapper
		+mq-min(lg)
			width: 100%

	.home-service-list
		+mq-min(lg)
			.container
				max-width: none
				padding: 0

	.home-service-item
		position: relative
		display: block
		.home-service-item-img
			figure
				aspect-ratio: 960 / 300
				+mq-max(lg)
					aspect-ratio: 16 / 10
				img
					+fit-cover
		.home-service-item-caption
			+mq-min(lg)
				position: absolute
				bottom: 0
				left: 0
				width: 100%
				padding: r(14px) r(30px)
				background: gradient(blue)
				text-align: center
				+text(white)
			+mq-max(lg)
				margin-top: r(12px)