.detail-date
	+fz-lh(14px, 18px)
	+text(grey-999)

.news-detail-meta
	padding-bottom: r(20px)
	border-bottom: 1px solid color(grey-light)
	margin-bottom: r(20px)

.news-other-list
	.news-item
		&.news-row
			+mq-min(lg)
				.news-img
					+flex-width(45.071111111%)
					figure
						border-radius: 0px 0px r(67.5px) 0px
				.news-caption
					padding: r(6px) r(16px) r(12px) r(35px)
					gap: r(6px)
				.news-title
					+fz-lh(14px,18px)
				.link-view-details
					font-size: r(14px)