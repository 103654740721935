.product-detail-equipment-feature
	.feature-item
		+flex-gap-col(r(7px))
		text-align: center
		.icon
			display: flex
			+flex-center
			+circle(r(120px))
			+bg(main)
			+trans-all
			margin: auto
			+parent-state(":hover")
				+bg(red)
			img
				width: 50%
		.text
			+fz-lh(16px,26px)

.feature-popup
	width: r(968px)
	padding: 0
	+radius(r(10px))
	overflow: hidden
	.popup-content
		display: flex
		gap: r(40px)
		+mq-max(lg)
			flex-direction: column
			gap: r(30px)
	.img
		figure
			aspect-ratio: 389 / 557
			img
				+fit-cover
		+mq-min(lg)
			+flex-width(389 / 968 * 100%)
		+mq-max(lg)
			figure
				aspect-ratio: 16 / 10
	.content
		flex: 1 1 auto
		padding: 0 r(30px) r(30px)
		+mq-min(lg)
			padding: r(100px) r(40px) r(40px) 0
	.article-content
		+fz-lh(18px,26px)

.product-detail-other
	.swiper-container
		padding: r(10px)
		margin: r(-10px)