.service-cate-4
	.cate-img
		+mq-min(lg)
			figure
				aspect-ratio: 782 / 739
				img
					+fit-contain
	.cate-content
		+mq-min(lg)
			padding-right: r(48px)
	.cate-sub-img
		+remove(lg,max)
		+mq-min(lg)
			figure
				width: r(400px)
				aspect-ratio: 400 / 227
				margin-left: auto
				img
					+fit-contain