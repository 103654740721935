.about-intro
	.section-wrapper
		+mq-min(lg)
			overflow: hidden
			display: grid
			grid-template-rows: repeat(100, 1vh)
			grid-template-columns: repeat(100, 1vw)
			position: relative
	.about-intro-img
		+mq-min(lg)
			grid-column-start: 1
			grid-column-end: 60
			grid-row-start: 1
			grid-row-end: 101
		figure
			height: 100%
			img
				+fit-cover

	.about-intro-content-wrap
		+mq-min(lg)
			position: absolute
			top: r(100px)
			bottom: 0
			left: 0
			width: 100%
			z-index: 2

	.about-intro-content-wrap
		+mq-max(lg)
			background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%)
			padding: r(30px) 0
	.about-intro-content
		+text(white)
		+mq-min(lg)
			padding: r(60px) 0 r(60px) r(115px)
		+mq-max(xxl)
			padding-left: r(95px)
		+mq-max(lg)
			padding: 0
		.logo-icon
			margin-bottom: r(24px)
			margin-left: r(-15px)
			width: r(94px)
			figure
				aspect-ratio: 91 / 94
				img
					+fit-contain
			+mq-min(lg)
				margin-bottom: r(18px)
				margin-left: r(-60px)
				&:after
					content: ''
					display: block
					+box(r(50px), 1px)
					+bg(white)
					margin-top: r(32px)
					margin-left: r(60px)
		.article-content
			+fz-lh(18px,26px)
			+mq-min(lg)
				padding-right: r(40px)
				max-height: r(380px)
	.about-intro-bg
		+remove(lg,max)
		+mq-min(lg)
			grid-column-start: 60
			grid-column-end: 101
			grid-row-start: 1
			grid-row-end: 101
			position: relative
			z-index: 1
			background: linear-gradient(214.53deg, #06529E 6.97%, #2AA8E0 97.27%)