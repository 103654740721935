.form-group
	input[type="text"], input[type="date"], select, textarea
		background: #fff
		border: 1px solid color(grey-light)
		padding: 0 r(12px) !important
		width: 100%
		height: r(40px)
		font-style: normal !important
		+fz-lh(16px, 24px)
		appearance: none
	select
		appearance: none
		+select-bg($pos: 97%)
		option
			+text(body)
	textarea
		height: r(150px)
		padding-top: r(8px) !important
	.RadPicker
		width: 100% !important
		position: relative
		.rcCalPopup
			position: absolute
			right: r(10px)
			+center(Y)
			background-image: url(../img/calendar.png)
			background-position: center !important
	&.qradio
		ul
			margin-bottom: r(-20px)
			+columnizer(2,r(20px))
			li
				input
					+hide()
					&:checked ~ label
						color: color(blue)
						&:before
							content: '\f192'
				label
					display: flex
					&:before
						content: '\f111'
						font-family: "Font Awesome 6 Pro"
						margin-right: r(10px)
						position: relative

.frm-btnwrap
	margin-top: r(30px)

.frm-btn
	input[type="submit"]
		+trans-all
		+bg(main)
		+text(white)
		border: 0
		height: r(40px)
		font-weight: 700
		width: 100%
		cursor: pointer
		font-size: r(18px)
		text-transform: uppercase
		appearance: none

.frm-captcha
	display: flex
	flex-wrap: wrap-reverse
	margin-top: r(20px)
	flex-direction: row-reverse
	justify-content: flex-end
	gap: r(20px)
	label
		display: none
	.frm-captcha-input
		flex: 1
	.RadCaptcha
		display: flex
		flex-direction: column-reverse
		flex: 1
		max-width: fit-content
		img
			height: r(40px) !important
		> span
			color: red
			display: block
			margin-top: 5px
		> div
			> div
				display: flex
				align-items: center
		a
			font-size: 0
			margin-left: 20px
			display: none !important
			&:before
				font-family: "Font Awesome 6 Pro"
				content: '\f2ea'
				font-size: r(27px)
				color: #333

.form-container
	.wrap-form
		.qradio
			.label
				display: none
		.frm-btn
			height: r(40px)
			width: 100%
			input[type="submit"]
				width: 100%
				min-width: auto
		.frm-btnwrap
			width: r(180px)
			margin-top: 0 !important
			+mq-max(sm)
				width: 100%

.wrap-form
	.row
		+gap-override(20px)