$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
.btn
	display: inline-flex
	+flex-center
	+reset-link
	padding: 0 1rem
	height: r(44px)
	min-width: r(150px)
	font-weight: 700
	text-transform: uppercase
	cursor: pointer
	transition: 0.3s all
	gap: r(12px)
	font-size: r(14px)

@each $color, $value in $colors
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(body), color(white))
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, color(body), color(white))

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(r(60px))
	+text(grey-999)
	font-size: r(32px)
	border: 1px solid color(grey-999)
	position: absolute
	+center(Y)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+mq-min(lg)
		margin: 0 r(30px)
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	&-white
		border-color: #fff
		+text(white)

	+on-hover
		+bg(red)
		+text(white)
		border-color: color(red)

.link-view-details
	display: inline-flex
	gap: r(10px)
	font-size: r(14px)
	font-weight: 700
	span
		+text(red)

.fancybox-button
	+bg(main)
	width: r(40px)
	height: r(40px)
	display: flex
	+flex-center
	svg
		width: r(30px)
		height: r(30px)
		path
			fill: white