.about-achievement
	.about-achievement-bg
		+bg-opa(main, 0.05)
		padding: r(40px) 0
		+mq-min(lg)
			padding: r(60px) 0 0
			display: flex
			align-items: center
	.achievement-slider
		.swiper-btn
			top: 30%
		.swiper-slide
			width: calc(25% - 1.5625rem)
			margin-right: r(30px)
			height: auto

	.achievement-item
		height: 100%
		display: flex
		flex-direction: column

	.achievement-item-img
		height: r(279px)
		display: flex
		+flex-center
		padding: r(36px)
		+bg(grey-bright)

	.achievement-item-title
		margin-top: r(44px)
		margin-bottom: r(10px)
		height: r(48px)
		+line(2)
		+mq-max(lg)
			margin-top: r(24px)

	.achievement-item-brief
		+fz-lh(16px, 24px)
		margin-bottom: r(43px)
		+mq-max(lg)
			margin-bottom: r(24px)

	.divider
		+border($color: grey-light)
		margin-bottom: r(35px)
		margin-top: auto
		position: relative
		+mq-max(lg)
			margin-bottom: r(16px)
		&:before
			+pseudo
				+circle(r(10px))
				left: 0
				+center(Y)
				+bg(main)