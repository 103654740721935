.product-sub-nav-wrap
	padding: r(24px) 0 r(30px)
	.product-sub-nav
		+flex-gap(r(80px))
		+mq-min(lg)
			justify-content: center
		+mq-max(md)
			gap: r(40px)
			overflow-x: auto
			display: none
			padding: 1rem
			box-shadow: 0 4px 4px rgba(0,0,0,.1)
		li
			+mq-max(md)
				flex: 1
				white-space: nowrap
				+after-first(1)
					margin-top: r(16px)
			a
				text-transform: uppercase
				+text(grey-half)
				position: relative
				display: inline-block
				padding-bottom: r(14px)
				+fz-lh(18px,24px)
				font-weight: 700
				+parent-state(".active",":hover")
					+text(blue)
				+mq-max(lg)
					padding-bottom: 0.125rem
				&:before
					+pseudo
					width: 100%
					height: 1px
					+bg(main)
					bottom: 0
					left: 0
					opacity: 0
					+context('li','li.active')
						opacity: 1

.product-sub-nav-mobile
	+remove(md,min)
	display: flex
	align-items: center
	justify-content: space-between
	padding: r(16px)
	+bg(blue)
	+text(white)