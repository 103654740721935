.page-piling-container
	+mq-min(lg)
		+fill
		position: fixed
		z-index: 20
		section
			height: 100vh

.fp-section
	will-change: transform

.section-wrapper
	> .Module
		height: 100%
		> .ModuleContent
			height: 100%