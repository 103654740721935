.product-compare
	position: fixed
	z-index: 99
	width: 100%
	bottom: 0
	left: 0
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.15)
	background-color: #f5f5f5
	.btn-minimize
		font-size: r(30px)
		color: #666
		position: absolute
		right: 0
		top: 20px
		cursor: pointer
	.compare-body
		position: relative
		padding: r(70px) 0
	.product
		position: relative
		.remove-cpr
			position: absolute
			top: r(20px)
			right: r(20px)
			cursor: pointer
			font-size: 20px
		.img
			padding-top: 100%
			background: #fff
			position: relative
			display: block
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				max-width: 80%
		.caption
			margin-top: 1rem
			+fz-lh(20px,27px)
	@media screen and ( max-width: 1024px )
		.compare-body
			padding: r(40px) 0

.compare-products
	table
		width: 100%
		line-height: 1.5
		+fz-lh(16px,24px)
		+bg(grey-light)
		border: 1px solid color(grey-light)
		.product-img
			width: r(215px)
			margin: 0 auto
		figure
			aspect-ratio: 215 / 120
			display: block
			img
				width: 100%
				height: 100%
				object-fit: contain
		tr
			+bg(white)
			th
				font-weight: 700
				text-align: left
				padding: r(12px) r(30px)
				width: 25%
			td
				padding: r(12px) r(30px)
				text-align: center
				vertical-align: top
				width: 25%
				&:first-child
					font-weight: 700
			+odd
				+bg(grey-bright)
		.product-header
			td
				border-bottom: 0
				padding-bottom: 0
				position: relative
				.remove-button
					position: absolute
					top: 0
					right: 0
					padding: 8px 12px
					font-size: 15px
					cursor: pointer
		.product-name
			td
				font-size: 2rem
				text-align: center !important
				border-top: 0
