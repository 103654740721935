.fp-nav
	right: r(24px)
	+center(Y)
	position: fixed
	z-index: 21
	+remove(lg,max)
	ul
		+flex-gap-col(r(28px))
		li
			width: auto
			height: auto
			margin: 0
			position: relative
			a
				display: block
				position: relative
				z-index: 1
				&:before
					+pseudo
						border: 1px solid color(main)
						+bg(white)
						+circle(r(20px))
						+center(both)
						opacity: 0
						+trans-all
						+parent-state('.active')
							opacity: 1
				span
					+circle(r(12px))
					+bg(main)
					border: 0 !important
					position: absolute
					+center(both)
					display: block
			.tooltip
				position: absolute
				+bg(white)
				+text(body)
				padding: r(8px) r(32px) r(8px) r(12px)
				font-size: r(12px)
				font-weight: 700
				right: r(-14px)
				+radius(999px)
				+center(Y)
				white-space: nowrap
				pointer-events: none
				opacity: 0
				+trans-all
				+parent-state('.active')
					opacity: 1