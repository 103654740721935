.dealer-wrap
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05)
	
.dealer-map
	width: 100%
	height: 100%
	#map_canvas,#mapIframe
		height: r(504px)
		width: 100%
		+mq-min(lg)
			padding-left: r(10px)

.dealer-result-list-wrap
	height: 100%
	.dealer-result-list
		max-height: r(504px)
		overflow-y: auto
		+scrollbars(5px, #06529E, color(grey-bright))
		padding-right: r(6px)
		.dealer-item-wrap
			cursor: pointer
			padding: 0 r(30px)
			transition: 0.3s all
			&.active
				+bg(main)
				+text(white)
			.dealer-item
				padding: r(25px) 0
				p 
					+fz-lh(16px, 20px)
					&:not(.name)
						display: flex
					& + p
						margin-top: r(7px)
					&:before
						font-family: "Font Awesome 6 Pro"
						margin-right: r(22px)
						font-weight: 300
						width: r(18px)
				.name
					+font(700, 20px, 24px)
					+text(blue-2)
					margin-bottom: r(19px)
					transition: 0.3s all
					+context(".dealer-item-wrap",".dealer-item-wrap.active")
						+text(white)
				.address
					&:before
						content: "\f3c5"
				.phone
					&:before
						content: "\f095"
				.fax
					&:before
						content: "\f67d"

			& + .dealer-item-wrap
				.dealer-item
					border-top: 1px solid #e5e5e5
