.img-zoom-out
	img
		+trans-all
	+on-hover
		img
			transform: scale(1.05)

.img-zoom-in
	img
		+trans-all
		transform: scale(1.05)
	+on-hover
		img
			transform: scale(1)