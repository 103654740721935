.home-news
	.Module
		height: auto !important
		width: 100%
		.ModuleContent
			height: 100%
	.home-news-bg
		+mq-max(lg)
			padding: r(40px) 0
		+mq-min(lg)
			display: flex
			align-items: center
	.home-news-wrapper
		width: 100%