.service-cate-bg
	+mq-max(lg)
		padding: r(40px) 0
	+mq-min(lg)
		display: flex
		align-items: center

	
.service-cate-1
	.sub-cate-list
		margin-top: r(37px)
		+mq-max(lg)
			margin-top: r(24px)
		ul
			li
				+flex-gap(r(15px))
				align-items: center
				+fz-lh(24px, 33px)
				+has-divider-bottom(r(15px), color(blue-2))
	.btn
		margin-top: r(15px)
	.cate-img
		+mq-min(lg)
			figure
				aspect-ratio: 970 / 577
				img
					+fit-contain