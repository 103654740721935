.about-chart
	.section-wrapper
		display: flex
		align-items: center
		+mq-max(lg)
			padding: r(40px) 0
	.chart-img
		+mq-min(lg)
			height: r(480px)
		figure
			aspect-ratio: 1410 / 480
			img
				+fit-contain